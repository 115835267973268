:root {
    --font-size-xs: 0.75rem; /* 12px */
    --line-height-xs: 1rem; /* 16px */
    --font-size-sm: 0.875rem; /* 14px */
    --line-height-sm: 1.25rem; /* 20px */
    --font-size-base: 1rem; /* 16px */
    --line-height-base: 1.5rem; /* 24px */
    --font-size-lg: 1.125rem; /* 18px */
    --line-height-lg: 1.75rem; /* 28px */
    --font-size-xl: 1.25rem; /* 20px */
    --line-height-xl: 1.75rem; /* 28px */
    --font-size-2xl: 1.5rem; /* 24px */
    --line-height-2xl: 2rem; /* 32px */
    --font-size-3xl: 1.875rem; /* 30px */
    --line-height-3xl: 2.25rem; /* 36px */
    --font-size-4xl: 2.25rem; /* 36px */
    --line-height-4xl: 2.5rem; /* 40px */
    --font-size-5xl: 3rem; /* 48px */
    --line-height-5xl: 1;
    --font-size-6xl: 3.75rem; /* 60px */
    --line-height-6xl: 1;
    --font-size-7xl: 4.5rem; /* 72px */
    --line-height-7xl: 1;
    --font-size-8xl: 6rem; /* 96px */
    --line-height-8xl: 1;
    --font-size-9xl: 8rem; /* 128px */
    --line-height-9xl: 1;
}
