.sidebar-nav
  display: flex
  justify-content: space-between

@media #{$breakpoint-smaller-than-sm-768px}
  .sidebar-nav
    justify-content: start
    .sidebar-nav-item:not(:last-child)
      margin-right: .5rem

a.sidebar-nav-item, a.step-button
  @extend .ui-button-m
  @extend .truncate
  padding: .5rem 1rem
  margin: 0
  color: $bleu-dark
  background-color: $light-color
  transition: color $transition-duration
  border-radius: 4px
  display: block
  text-decoration: none

  &:hover
    background-color: $bleu

  &.active
    color: $light-color
    background-color: $bleu-dark

  i + span
    display: none

@media #{$breakpoint-larger-than-sm-768px}
  .sidebar-nav
    flex-direction: column

  a.sidebar-nav-item, a.step-button
    display: flex
    align-items: center
    padding: spacer(2)
    white-space: inherit
    color: $gris-fonce
    background-color: inherit

    &:hover
      background-color: inherit
      color: $bleu-dark

    &.active
      color: $bleu-dark
      background-color: $bleu-light

    i[class^="ph-"], i[class*=" ph-"]
      margin-right: spacer(1)

      & + span
        display: inherit
        background-color: inherit
