.summary-item
  padding-top: 0.5rem
  padding-bottom: 0.5rem
  margin-bottom: 0.5rem
  border-bottom: 1px solid $gris

  .summary-item__flex-wrapper
    display: flex
    justify-content: space-between
    align-items: center

    .summary-value
      text-align: right
      font-weight: bold

  &:first-child
    border-top: 1px solid $gris

  .summary-full-width
    flex-basis: 100%
