.form_message
  margin: spacer(2) 0
  line-height: 1.5em
  color: $noir
  display: flex
  border-radius: 0 4px 4px 0
  width: 100%
  i[class^="ph-"]
    display: flex
    align-items: center
    font-family: $icon-font
    font-size: 1.5em
  .message__type
    display: flex
    border-radius: $border-radius 0 0 $border-radius
    padding: spacer(1)
    i[class^="ph-"]
      color: $blanc
  .message__content
    padding: spacer(2)
    border: 1px solid $gris
    border-left: none
    border-radius: 0 4px 4px 0
    flex-grow: 1
    .message__description
      >p  // override the default p margins here
        margin-block: 0
      >p+p
        margin-top: spacer(1)
  .message__title
    font-weight: bold
  a
    display: inline

.form_message.message--warning
  .message__type
    background-color: $rouge-dark
  a
    color: $rouge-dark

.form_message.message--info
  .message__type
    background-color: $bleu
  a
    color: $bleu

.form_message.message--trigger
  .message__content
    border-right: 0
    border-radius: 0
  i.ph-caret-right-bold
    border-radius: 0 4px 4px 0
    background-color: transparent
    color: $bleu
    border: 1px solid $gris
    border-left: none
    &:before
      margin-right: spacer(1)
