@mixin table-border-bottom
  border-bottom: 1px solid $gris

.table
  &.table-no-header
    border-top: 1px solid $gris

  @extend .ui-s-reg
  .table-header
    @extend .ui-label-reg
    padding: spacer(3) spacer(1)
    @include table-border-bottom
    > * > *
      // This aligns the sorting icon with the column name
      display: flex
      align-items: center
    a
      display: flex
      margin-left: spacer(1)
      color: $gris
      transition: color $transition-duration
      &:hover
        color: $gris-fonce

  .table-line
    display: inherit
    @include table-border-bottom
    padding: spacer(2) spacer(1)

    .radio_button
      margin-bottom: 0
  .table-content
    @include table-border-bottom
    padding: spacer(2) spacer(1) spacer(2) spacer(1)
    background-color: $light-color

  .table-line-errors
    padding: spacer(2) spacer(1)

  .table-line-collapse
    border: none
    background-color: $light-color

  .table-line-collapse-with-bottom-border
    @include table-border-bottom

  .closed
    .table-line-collapse
      @include table-border-bottom
      padding-bottom: spacer(2)
    .table-line
      background-color: $blanc
      transition: background-color $transition-duration
      &:hover
        background-color: $light-color
