@mixin dropdown-container
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2)
  border-radius: 4px
  border: 1px solid $gris
  background-color: $blanc
  position: absolute
  top: 100%
  margin-top: 1rem
  padding: 1rem
  text-align: left

@mixin hover-highlight
  &:hover
    color: $bleu-dark

@mixin active-checkbox
  /* The checkboxes are made with a tilted border */
  &.active::after
    content: ""
    display: inline-block
    font-size: inherit
    margin-left: 8px
    transform: rotate(45deg)
    height: 10px
    width: 5px
    border-bottom: 3px solid #005D82
    border-right: 3px solid #005D82
    border-radius: 2px

.dropdown-trigger
  position: relative

  .dropdown
    display: none
    &.active
      display: flex
      @include dropdown-container
      flex-direction: column
      width: auto
      z-index: 1
      max-height: 400px
      overflow-y: auto

    &.dropdown-top
      top: inherit
      bottom: 100%
      margin-bottom: 1rem

    &.dropdown-no-wrap
      right: 0
      white-space: nowrap

    a
      @include hover-highlight
      @include active-checkbox

    a:not(:last-of-type)
      padding-bottom: 0.5em
