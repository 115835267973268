@use "sass:map"

.bg-gris-clair
  background-color: $gris-clair
.bg-gris
  background-color: $gris
.bg-gris-neutre
  background-color: $gris-neutre
.bg-gris-fonce
  background-color: $gris-fonce
.bg-noir
  background-color: $noir

.text-gris-clair
  color: $gris-clair
.text-gris
  color: $gris
.text-gris-neutre
  color: $gris-neutre
.text-gris-fonce
  color: $gris-fonce
.text-noir
  color: $noir

.border-gris-clair
  border-color: $gris-clair
.border-gris
  border-color: $gris
.border-gris-neutre
  border-color: $gris-neutre
.border-gris-fonce
  border-color: $gris-fonce
.border-noir
  border-color: $noir

@mixin bg-color($color)
  .bg-#{"" + $color}
    background-color: map.get($theme-colors, "#{$color}")
  .bg-#{"" + $color}-deep
    background-color: map.get($theme-colors, "#{$color}-deep")
  .bg-#{"" + $color}-dark
    background-color: map.get($theme-colors, "#{$color}-dark")
  .bg-#{"" + $color}-light
    background-color: map.get($theme-colors, "#{$color}-light")
  .bg-#{"" + $color}-xlight
    background-color: map.get($theme-colors, "#{$color}-xlight")

@mixin text-color($color)
  .text-#{"" + $color}
    color: map.get($theme-colors, "#{$color}")
  .text-#{"" + $color}-deep
    color: map.get($theme-colors, "#{$color}-deep")
  .text-#{"" + $color}-dark
    color: map.get($theme-colors, "#{$color}-dark")
  .text-#{"" + $color}-light
    color: map.get($theme-colors, "#{$color}-light")
  .text-#{"" + $color}-xlight
    color: map.get($theme-colors, "#{$color}-xlight")

@mixin border-color($color)
  .border-#{"" + $color}
    border-color: map.get($theme-colors, "#{$color}")
  .border-#{"" + $color}-deep
    border-color: map.get($theme-colors, "#{$color}-deep")
  .border-#{"" + $color}-dark
    border-color: map.get($theme-colors, "#{$color}-dark")
  .border-#{"" + $color}-light
    border-color: map.get($theme-colors, "#{$color}-light")
  .border-#{"" + $color}-xlight
    border-color: map.get($theme-colors, "#{$color}-xlight")

@include bg-color(beige)
@include bg-color(blanc)
@include bg-color(bleu)
@include bg-color(bleu-fonce)
@include bg-color(brun)
@include bg-color(jaune)
@include bg-color(rouge)
@include bg-color(turquoise)
@include bg-color(vert)

@include text-color(beige)
@include text-color(blanc)
@include text-color(bleu)
@include text-color(bleu-fonce)
@include text-color(brun)
@include text-color(jaune)
@include text-color(rouge)
@include text-color(turquoise)
@include text-color(vert)

@include border-color(beige)
@include border-color(blanc)
@include border-color(bleu)
@include border-color(bleu-fonce)
@include border-color(brun)
@include border-color(jaune)
@include border-color(rouge)
@include border-color(turquoise)
@include border-color(vert)
