// Responsive grid layout for `.vc-grid-tile`

.vc-grid-tile
  display: grid
    grid-auto-rows: minmax(100px, auto)

// Below 768px: 2 columns, `spacer(2)` gap, rows min 100px; `.vc-grid-tile--large` uses 1 column.
@media #{$breakpoint-smaller-than-sm-768px}
  .vc-grid-tile
    grid-template-columns: repeat(2, 1fr)
    grid-gap: spacer(2)

    &.vc-grid-tile--large
      grid-template-columns: repeat(1, 1fr)

// Above 768px: 3 columns, `spacer(3)` gap, rows min 100px.
@media #{$breakpoint-larger-than-sm-768px}
  .vc-grid-tile
    grid-template-columns: repeat(3, 1fr)
    grid-gap: spacer(3)
