.information-panel
  background-color: $light-color
  padding: spacer(2)
  margin-bottom: spacer(2)

  .label
    @extend .ui-label-reg
    margin-bottom: spacer(1)

  .value
    @extend .ui-m-med
    display: flex
    justify-content: space-between
