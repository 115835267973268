@use "sass:map"

.grid-cols-none-xs
  .vc-tile
    .vc-spinner
      margin: 0 3rem 1rem

@media #{$breakpoint-smaller-than-xl-1600px}
  .grid-cols-none-xs
    .vc-tile
      .vc-spinner
        margin: 0 1rem 1rem

.vc-tile
  cursor: pointer
  color: $gris-nuit
  background-color: $blanc
  border: 2px solid $gris-neutre
  border-radius: 4px
  padding: 0.5em
  display: flex
  flex-direction: column
  align-items: center
  text-align: center

  .vc-spinner
    margin: 0 1rem 1rem

  .vc-number-with-unit
    border: none

    &:focus-within
      outline: none

    input[type="text"]
      padding: .5rem .5rem .5rem 0

  &.active,
  &:has(input:checked)
    color: $bleu-dark
    background-color: $bleu-light
    border-color: $bleu-light
    font-weight: 600

  &:hover,
  &.active:hover,
  &:has(input:checked):hover
    color: $bleu-dark
    border-color: currentColor

  &:has(input:focus)
    color: $bleu-dark
    border-color: currentColor

  // NOTE: les icônes native de Phosphor et celles créées par nos soins
  // présentent des proportions différentes. Ces quelques règles visent à
  // gommer ces différences dans le contexte du TileComponent, où elles sont
  // particulièrement visibles.
  // FIXME: ces règles seront à retirer dès lors que nous aurons remplacé les
  // polices d'icônes par une utilisation astucieuse de fichiers SVG.
  i[class*=" ph-"],
  i[class^="ph-"]
    padding: var(--padding-3)
    font-size: var(--font-size-5xl)
    line-height: var(--line-height-5xl)

  i[class*=" ph-"][class*="-enercoop"],
  i[class^="ph-"][class*="-enercoop"]
    padding: 0
    font-size: var(--font-size-7xl)
    line-height: var(--line-height-7xl)

  @supports not selector(:has(a, b))
    &.active
      color: $bleu-dark
      background-color: $bleu-light
      border-color: $bleu-light
      font-weight: 600

    &:hover,
    &.active:hover
      color: $bleu-dark
      border-color: currentColor

  input[type="checkbox"], input[type="radio"]
    margin-right: 0

// Themes
@mixin vc-tile-theme($color)
  .vc-tile[data-theme="#{$color}"]
    &.active,
    &:has(input:checked)
      color: map.get($theme-colors, "#{$color}-dark")
      background-color: map.get($theme-colors, "#{$color}-light")
      border-color: map.get($theme-colors, "#{$color}-light")

    &:hover,
    &.active:hover,
    &:has(input:checked):hover
      color: map.get($theme-colors, "#{$color}-dark")

    &:has(input:focus)
      color: map.get($theme-colors, "#{$color}-dark")

    @supports not selector(:has(a, b))
      &.active
        color: map.get($theme-colors, "#{$color}-dark")
        background-color: map.get($theme-colors, "#{$color}-light")
        border-color: map.get($theme-colors, "#{$color}-light")

      &:hover,
      &.active:hover
        color: map.get($theme-colors, "#{$color}-dark")

@include vc-tile-theme("beige")
@include vc-tile-theme("blanc")
@include vc-tile-theme("bleu")
@include vc-tile-theme("brun")
@include vc-tile-theme("jaune")
@include vc-tile-theme("rouge")
@include vc-tile-theme("turquoise")
@include vc-tile-theme("vert")
