$box-bg: white !default

.box
  @extend .p-2
  border: 1px solid $bleu-light
  background-color: $box-bg
  height: auto
  flex-grow: 1
  display: flex
  flex-direction: column
  text-decoration: none

  &.vertical-box-img
    picture
      display: block
      margin: 2rem auto 3rem
    img
      max-width: 80%
      height: auto

  .row
    flex-grow: 1

  .box-img
    max-width: 100%
    max-height: 6rem
    margin-left: auto
    margin-right: auto
    display: block

  .title
    @extend .ui-m-med
    display: flex
    align-items: center
    h5
      color: $bleu

    i
      margin-right: spacer(1)
    &-turquoise
      color: $turquoise
    &-bleu
      color: $bleu
    &-rouge
      color: $rouge
    &-vert
      color: $vert
    &-bleu-fonce
      color: $bleu-fonce
    &-jaune
      color: $jaune-dark

  .content
    margin-top: spacer(2)
    display: flex
    flex-direction: column
    flex-grow: 1
    justify-content: space-between

  &.large-title
    .title
      @extend .ui-l-semi
      @extend .mb-1

  &[href]:hover
    border-color: $bleu-fonce

.box-footer
  border: 1px solid $bleu-light
  background-color: $box-bg
  padding: spacer(2)
  display: flex
  justify-content: center
  border-top: 0

@each $color, $value in $theme-main-colors
  .box-bg-#{$color}
    background-color: $value

  .box-title-#{$color}
    color: $value

  .box-content-#{$color}
    font-weight: bold
    color: $value

  .box-link-underline-#{$color}
    color: $value
    text-decoration: underline

@media #{$breakpoint-larger-than-sm-768px}
  .box .box-img
    max-height: 10rem
