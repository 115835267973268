.header-title
  @extend .ui-s-reg
  background-color: $light-color
  padding: spacer(4) 0
  margin-bottom: spacer(4)

  h1
    @extend .h5-size

  * > :last-child
    margin-bottom: 0

@media #{$breakpoint-smaller-than-sm-768px}
  .header-title + .header-navigation
    padding: spacer(4) 0
    margin-top: - spacer(4)
    margin-bottom: spacer(4)
