body
  background: white
  @extend .ui-s-reg



.blockquote
  span::before
    content: "\201C"
    margin-right: 0.25rem
  span::after
    margin-left: 0.25rem
    content: "\201D"
