$title-font: "Anton", sans-serif
$base-font: "Roboto", sans-serif

$base-size: 1em //16px

$button-font-size: 18px

$icon-font: 'Phosphor'

i[class^="ph-"], i[class*=" ph-"]
  font-size: 24px

i.ph-void::after
  content: ' '
