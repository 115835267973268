@use "sass:map"

footer.vc-footer
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: auto
  gap: var(--padding-4)
  color: $blanc
  padding: var(--padding-8)
  margin-top: var(--margin-8)

  section
    header
      font-weight: bold
      margin-bottom: var(--margin-4)

    p span
      display: block

    &.vc-footer__section--rogervoice
      i
        margin-right: 0
      &:hover i
        margin-left: 3px
        margin-right: -3px

  nav.vc-footer__navigation
    ul
      padding: 0
      margin-top: var(--margin-4)
      list-style-type: none
      li
        display: block
        margin: 0

@media #{$breakpoint-larger-than-sm-768px}
  footer.vc-footer
    grid-template-columns: 200px 1fr 1fr
    gap: var(--padding-8)

    section.vc-footer__section
      &:nth-child(2)
        grid-column: 2
        grid-row: 1 / span 2
      &:nth-child(n+4)
        grid-column: 3
        grid-row: 2

    nav.vc-footer__navigation
      grid-column: 1 / span 2
      grid-row: 3

      ul
        margin-top: 0
        li
          display: inline-block
          margin-left: var(--margin-4)
          &:first-child
            margin-left: 0

    section.vc-footer__legal
      grid-row: 3

@media #{$breakpoint-larger-than-md-992px}
  footer.vc-footer
    grid-template-columns: 200px 2fr 1fr 1.5fr

    section.vc-footer__section
      &:nth-child(2)
        grid-column: 2
        grid-row: 1
      &:nth-child(n+4)
        grid-column: 4
        grid-row: 1

    nav.vc-footer__navigation
      grid-column: 1 / span 3
      grid-row: 2

    section.vc-footer__legal
      grid-row: 2

@media #{$breakpoint-larger-than-xl-1600px}
  footer.vc-footer
    grid-template-columns: 200px 3fr 1fr 1fr

    section.vc-footer__section p span
      display: inline

// Themes
@mixin vc-footer-theme($color)
  footer.vc-footer[data-theme="#{$color}"]
    background-color: map.get($theme-colors, $color)

@include vc-footer-theme("bleu")
@include vc-footer-theme("rouge")
