@use "sass:map"

.summary-section
  max-width: 30rem
  margin-left: auto
  margin-right: auto
  font-size: 0.875rem
  &:last-child
    margin-bottom: 3rem
  .summary-section-list
    p
      margin-top: 1rem
  .summary-section-header
    display: flex
    justify-content: space-between
    .summary-section-link
      color: $gris-nuit
      display: flex
      align-items: center
      margin-top: 3rem
      margin-bottom: 1rem
      flex-grow: 0
      font-weight: bold
      text-decoration: none
      &:hover
        color: $noir
      i
        margin-right: 0

// Themes
@mixin vc-summary-section-theme($color)
  .summary-section[data-theme="#{$color}"]
    .summary-section-link
      color: map.get($theme-colors, $color)
      &:hover
        color: map.get($theme-colors, "#{$color}-dark")

@include vc-summary-section-theme("beige")
@include vc-summary-section-theme("blanc")
@include vc-summary-section-theme("bleu")
@include vc-summary-section-theme("brun")
@include vc-summary-section-theme("jaune")
@include vc-summary-section-theme("rouge")
@include vc-summary-section-theme("turquoise")
@include vc-summary-section-theme("vert")
