.flex
  display: -ms-flexbox
  display: -webkit-box
  display: flex
  flex-wrap: nowrap

  &.space-between
    justify-content: space-between

  &-justify-center
    @extend .flex
    justify-content: center

  &-justify-end
    @extend .flex
    justify-content: end

  &-align-center
    @extend .flex
    align-items: center

  &-align-start
    @extend .flex
    align-items: flex-start

  &-align-stretch
    @extend .flex
    align-items: stretch

  &-height-and-center
    @extend .flex
    justify-content: center
    align-items: center
    min-height: 100%

.flex-column
  flex-flow: column nowrap
  display: flex

.flex-col
  flex-direction: column

.justify-center
  justify-content: center

.justify-between
  justify-content: space-between

.justify-around
  justify-content: space-around

.flex-wrap
  display: flex
  flex-wrap: wrap

@each $number, $size in $spacers
  .m-#{$number}
    margin: spacer($number)
  .mt-#{$number}
    margin-top: spacer($number)
  .mb-#{$number}
    margin-bottom: spacer($number)
  .mr-#{$number}
    margin-right: spacer($number)
  .ml-#{$number}
    margin-left: spacer($number)
  .mh-#{$number}
    margin-left: spacer($number)
    margin-right: spacer($number)
  .mv-#{$number}
    margin-top: spacer($number)
    margin-bottom: spacer($number)

  .p-#{$number}
    padding: spacer($number)
  .pt-#{$number}
    padding-top: spacer($number)
  .pb-#{$number}
    padding-bottom: spacer($number)
  .pr-#{$number}
    padding-right: spacer($number)
  .pl-#{$number}
    padding-left: spacer($number)
  .ph-#{$number}
    padding-left: spacer($number)
    padding-right: spacer($number)
  .pv-#{$number}
    padding-top: spacer($number)
    padding-bottom: spacer($number)

.ml-auto
  margin-left: auto

@mixin prismic-image
  width: 100%
  height: 100%
