.form-title
  font-family: $title-font
  font-size: 1rem
  margin-bottom: 1rem
  display: flex
  align-items: center
  margin-top: spacer(6)

  i[class^="ph-"], i[class*=" ph-"]
    font-weight: bold
    font-size: 1.5rem
    margin-right: spacer(1)

  // fix a display bug for bank icons
  i.ph-bank, i.ph-bank-bold
    font-weight: inherit

  .form-title__subtitle
    font-size: var(--font-size-sm)
    line-height: var(--line-height-sm)
    margin-left: var(--margin-4)
    color: $gris-fonce
    font-family: $base-font

