// SCSS files because we can multiline
// Colors are generated using https://uicolors.app/

@import "uicolors";

// Neutral
$gris-clair: $white-100;  // formerly #F5F5F5;
$gris: $white-200;        // formerly #C4C4C6;
$gris-neutre: $white-300; // formerly #BBBBBB;
$gris-fonce: $white-400;  // formerly #909090;
$gris-nuit: $white-700;
$noir: $white-950;        // formerly #242424;

// Shades
$beige: $stark-white-100;
$blanc: $white-50;
$bleu-fonce: $steel-blue-500; //still in use in Prismic
$bleu: $steel-blue-500;
$brun: $nutmeg-800;
$jaune: $yellow-sea-500;
$rouge: $mandy-500;
$turquoise: $keppel-400;
$vert: $fruit-salad-500;

// deep versions
$beige-deep: $stark-white-400;
$blanc-deep: $white-700;
$bleu-fonce-deep: $steel-blue-700;
$bleu-deep: $steel-blue-700;
$brun-deep: $nutmeg-950;
$jaune-deep: $yellow-sea-700;
$rouge-deep: $mandy-700;
$turquoise-deep: $keppel-700;
$vert-deep: $fruit-salad-700;

// dark versions (hover)
$beige-dark: $stark-white-200;
$blanc-dark: $white-600;
$bleu-fonce-dark: $steel-blue-600;
$bleu-dark: $steel-blue-600;
$brun-dark: $nutmeg-900;
$jaune-dark: $yellow-sea-600;
$rouge-dark: $mandy-600;
$turquoise-dark: $keppel-600;
$vert-dark: $fruit-salad-600;

// light versions
$beige-light: $stark-white-50;
$blanc-light: $white-200;
$bleu-fonce-light: $steel-blue-200;
$bleu-light: $steel-blue-200;
$brun-light: $nutmeg-200;
$jaune-light: $yellow-sea-200;
$rouge-light: $mandy-200;
$turquoise-light: $keppel-200;
$vert-light: $fruit-salad-200;

// Xlight versions
$beige-xlight: $white-50;
$blanc-xlight: $white-50;
$bleu-fonce-xlight: $steel-blue-50;
$bleu-xlight: $steel-blue-50;
$brun-xlight: $nutmeg-50;
$jaune-xlight: $yellow-sea-50;
$rouge-xlight: $mandy-50;
$turquoise-xlight: $keppel-50;
$vert-xlight: $fruit-salad-50;

$theme-colors: (
  "vert": $vert,
  "vert-deep": $vert-deep,
  "vert-dark": $vert-dark,
  "vert-light": $vert-light,
  "vert-xlight": $vert-xlight,

  "bleu": $bleu,
  "bleu-deep": $bleu-deep,
  "bleu-dark": $bleu-dark,
  "bleu-light": $bleu-light,
  "bleu-xlight": $bleu-xlight,

  "turquoise": $turquoise,
  "turquoise-deep": $turquoise-deep,
  "turquoise-dark": $turquoise-dark,
  "turquoise-light": $turquoise-light,
  "turquoise-xlight": $turquoise-xlight,

  "rouge": $rouge,
  "rouge-deep": $rouge-deep,
  "rouge-dark": $rouge-dark,
  "rouge-light": $rouge-light,
  "rouge-xlight": $rouge-xlight,

  "jaune": $jaune,
  "jaune-deep": $jaune-deep,
  "jaune-dark": $jaune-dark,
  "jaune-light": $jaune-light,
  "jaune-xlight": $jaune-xlight,

  "beige": $beige,
  "beige-deep": $beige-deep,
  "beige-dark": $beige-dark,
  "beige-light": $beige-light,
  "beige-xlight": $beige-xlight,

  "brun": $brun,
  "brun-deep": $brun-deep,
  "brun-dark": $brun-dark,
  "brun-light": $brun-light,
  "brun-xlight": $brun-xlight,

  "blanc": $blanc,
  "blanc-deep": $blanc-deep,
  "blanc-dark": $blanc-dark,
  "blanc-light": $blanc-light,
  "blanc-xlight": $blanc-xlight,
);

$theme-main-colors: (
  "vert": $vert,
  "bleu": $bleu,
  "bleu-fonce": $bleu-fonce,
  "turquoise": $turquoise,
  "rouge": $rouge,
  "jaune": $jaune,
  "beige": $beige,
  "brun": $brun,
  "blanc": $blanc,
);

@mixin danger {
  color: $rouge-dark;
  background: $rouge-light;
  &--solid {
    color: $blanc;
    background: $rouge-dark;
  }
}

@mixin info {
  color: $bleu-dark;
  background: $bleu-light;
  &--solid {
    color: $blanc;
    background: $bleu-dark;
  }
}

@mixin success {
  color: $vert-dark;
  background: $vert-light;
  &--solid {
    color: $blanc;
    background: $vert-dark;
  }
}

@mixin warning {
  color: $jaune-dark;
  background: $jaune-light;
  &--solid {
    color: $blanc;
    background: $jaune-dark;
  }
}

$schemes: (
  vert-aplat: (
    primary: $vert,
    secondary: $beige,
    negative: $beige,
    negative-inverse: $noir,
    primary-hover: $vert-dark,
    secondary-hover: $beige-light,
    vignette-text: $noir,
  ),
  vert-negatif: (
    primary: $beige,
    secondary: $vert,
    negative: $noir,
    negative-inverse: $vert,
    primary-hover: $beige-light,
    secondary-hover: $vert-dark,
    vignette-text: $beige,
  ),
  bleu-fonce-aplat: (
    primary: $bleu-fonce,
    secondary: $beige,
    negative: $beige,
    negative-inverse: $noir,
    primary-hover: $bleu-fonce-dark,
    secondary-hover: $beige-light,
    vignette-text: $noir,
  ),
  bleu-fonce-negatif: (
    primary: $beige,
    secondary: $bleu-fonce,
    negative: $noir,
    negative-inverse: $bleu-fonce,
    primary-hover: $beige-light,
    secondary-hover: $bleu-dark,
    vignette-text: $beige,
  ),
  bleu-aplat: (
    primary: $bleu,
    secondary: $beige,
    negative: $beige,
    negative-inverse: $noir,
    primary-hover: $bleu-dark,
    secondary-hover: $beige-light,
    vignette-text: $noir,
  ),
  bleu-negatif: (
    primary: $beige,
    secondary: $bleu,
    negative: $noir,
    negative-inverse: $bleu,
    primary-hover: $beige-light,
    secondary-hover: $bleu-dark,
    vignette-text: $beige,
  ),
  turquoise-aplat: (
    primary: $turquoise,
    secondary: $beige,
    negative: $beige,
    negative-inverse: $noir,
    primary-hover: $turquoise-dark,
    secondary-hover: $beige-light,
    vignette-text: $noir,
  ),
  turquoise-negatif: (
    primary: $beige,
    secondary: $turquoise,
    negative: $noir,
    negative-inverse: $turquoise,
    primary-hover: $beige-light,
    secondary-hover: $turquoise-dark,
    vignette-text: $beige,
  ),
  rouge-aplat: (
    primary: $rouge,
    secondary: $beige,
    negative: $beige,
    negative-inverse: $noir,
    primary-hover: $rouge-dark,
    secondary-hover: $beige-light,
    vignette-text: $noir,
  ),
  rouge-negatif: (
    primary: $beige,
    secondary: $rouge,
    negative: $noir,
    negative-inverse: $rouge,
    primary-hover: $beige-light,
    secondary-hover: $rouge-dark,
    vignette-text: $beige,
  ),
  jaune-aplat: (
    primary: $jaune,
    secondary: $beige,
    negative: $noir,
    negative-inverse: $noir,
    primary-hover: $jaune-dark,
    secondary-hover: $beige-light,
    vignette-text: $noir,
  ),
  jaune-negatif: (
    primary: $beige,
    secondary: $jaune,
    negative: $noir,
    negative-inverse: $jaune,
    primary-hover: $beige-light,
    secondary-hover: $jaune-dark,
    vignette-text: $beige,
  ),
  vert-aplat-blog: (
    primary: $vert,
    secondary: $blanc,
    negative: $blanc,
    negative-inverse: $noir,
    primary-hover: $vert-dark,
    secondary-hover: $blanc-dark,
    vignette-text: $noir,
  ),
  vert-negatif-blog: (
    primary: $blanc,
    secondary: $vert,
    negative: $noir,
    negative-inverse: $vert,
    primary-hover: $beige,
    secondary-hover: $vert-dark,
    vignette-text: $blanc,
  ),
  bleu-fonce-aplat-blog: (
    primary: $bleu-fonce,
    secondary: $blanc,
    negative: $blanc,
    negative-inverse: $noir,
    primary-hover: $bleu-fonce-dark,
    secondary-hover: $blanc-dark,
    vignette-text: $noir,
  ),
  bleu-fonce-negatif-blog: (
    primary: $blanc,
    secondary: $bleu-fonce,
    negative: $noir,
    negative-inverse: $bleu-fonce,
    primary-hover: $beige,
    secondary-hover: $bleu-dark,
    vignette-text: $blanc,
  ),
  bleu-aplat-blog: (
    primary: $bleu,
    secondary: $blanc,
    negative: $blanc,
    negative-inverse: $noir,
    primary-hover: $bleu-dark,
    secondary-hover: $blanc-dark,
    vignette-text: $noir,
  ),
  bleu-negatif-blog: (
    primary: $blanc,
    secondary: $bleu,
    negative: $noir,
    negative-inverse: $bleu,
    primary-hover: $beige,
    secondary-hover: $bleu-dark,
    vignette-text: $blanc,
  ),
  turquoise-aplat-blog: (
    primary: $turquoise,
    secondary: $blanc,
    negative: $blanc,
    negative-inverse: $noir,
    primary-hover: $turquoise-dark,
    secondary-hover: $blanc-dark,
    vignette-text: $noir,
  ),
  turquoise-negatif-blog: (
    primary: $blanc,
    secondary: $turquoise,
    negative: $noir,
    negative-inverse: $turquoise,
    primary-hover: $beige,
    secondary-hover: $turquoise-dark,
    vignette-text: $blanc,
  ),
  rouge-aplat-blog: (
    primary: $rouge,
    secondary: $blanc,
    negative: $blanc,
    negative-inverse: $noir,
    primary-hover: $rouge-dark,
    secondary-hover: $blanc-dark,
    vignette-text: $noir,
  ),
  rouge-negatif-blog: (
    primary: $blanc,
    secondary: $rouge,
    negative: $noir,
    negative-inverse: $rouge,
    primary-hover: $beige,
    secondary-hover: $rouge-dark,
    vignette-text: $blanc,
  ),
  jaune-aplat-blog: (
    primary: $jaune,
    secondary: $blanc,
    negative: $noir,
    negative-inverse: $noir,
    primary-hover: $jaune-dark,
    secondary-hover: $blanc-dark,
    vignette-text: $noir,
  ),
  jaune-negatif-blog: (
    primary: $blanc,
    secondary: $jaune,
    negative: $noir,
    negative-inverse: jaune,
    primary-hover: $beige,
    secondary-hover: $jaune-dark,
    vignette-text: $blanc,
  ),
);

/*
 * Implementation of schemes
 * source : https://medium.com/@dmitriy.borodiy/easy-color-theming-with-scss-bc38fd5734d1
 */
@mixin themify($schemes) {
  @each $scheme, $map in $schemes {
    .scheme-#{$scheme} & {
      $scheme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($schemes, $scheme), '#{$key}');
        $scheme-map: map-merge($scheme-map, ($key: $value)) !global;
      }

      // In this block, we set:
      // * $paragraph-link = $main-color for "negatif" schemes
      // * $paragraph-link = $noir for "aplat" schemes
      // * $vignette-text-link = $noir for "negatif" schemes
      // * $vignette-text-link = $main-color for "aplat" schemes
      @if (str-index($scheme, 'aplat')) {
        $main-color: map-get(map-get($schemes, $scheme), 'primary');
        $scheme-map: map-merge($scheme-map, (paragraph-link: $noir)) !global;
        $scheme-map: map-merge($scheme-map, (vignette-text-link: $main-color)) !global;
      }
      @if (str-index($scheme, 'negatif')) {
        $main-color: map-get(map-get($schemes, $scheme), 'secondary');
        $scheme-map: map-merge($scheme-map, (paragraph-link: $main-color)) !global;
        $scheme-map: map-merge($scheme-map, (vignette-text-link: $noir)) !global;
      }

      @content;
      $scheme-map: null !global;
    }
  }
}

@function schemed($key) {
  @return map-get($scheme-map, $key);
}
