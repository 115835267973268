@mixin input
  width: 100%
  border: 1px solid $gris
  box-sizing: border-box
  border-radius: 4px
  line-height: spacer(4)
  padding: spacer(1) spacer(2)

label
  display: flex
  align-items: center
  margin-bottom: spacer(1)

  [class^="ph-"]
    font-size: 1rem
    margin-right: spacer(1)

input[type="text"],
input[type="password"],
input[type="date"],
input[type="email"],
input[type="tel"],
select, .select
  @include input

  &:not(.mb-0) // just a quick fix, waiting for a better solution
    margin-bottom: spacer(2)

  &.input-small
    padding: 3px 11px
    background-color: $blanc

  &.error
    border: 1px solid $rouge

.field_with_errors
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="email"],
  input[type="tel"],
  select, .select
    border: 1px solid $rouge

input[type="email"]:invalid,
input[type="date"]:invalid,
input[type="tel"]:invalid
  border: 1px solid $rouge

input[type="text"]:read-only,
input[type="radio"]:read-only,
input[type="date"]:read-only,
input[type="tel"]:read-only,
input[type="email"]:read-only,
label.read-only,
select.read-only
  color: $gris-neutre
  cursor: default

input[type="text"]:read-only,
input[type="date"]:read-only,
input[type="tel"]:read-only,
input[type="email"]:read-only,
select.read-only
  background-color: $light-color

input[type="text"]:disabled,
input[type="date"]:disabled,
input[type="tel"]:disabled
  background: none

input[type=file] + .js-reset-file-field
  flex-shrink: 0
  &[data-default-visibility].active
    display: inline-block !important

select, .select
  @extend .truncate
  padding-right: 40px // To make sure that text is not hidden behind the arrow icon
  display: block
  background: none

  &:not([multiple])
    appearance: none
    background-image: url('images/ecgcc/caret-down-black.svg')
    background-position: right spacer(2) top 13px
    background-repeat: no-repeat

  &.input-small
    padding: 3px 11px
    padding-right: 40px
    background-position: right 5px top 8px
    background-color: $blanc

input[type="checkbox"], input[type="radio"]
  cursor: pointer
  height: 18px
  margin-right: spacer(1)

input[type="checkbox"]
  width: 18px
input[type="checkbox"]:disabled
  cursor: not-allowed
input[type="radio"]
  width: 20px

.checkbox, .radio_button
  display: flex
  align-items: flex-end
  margin-bottom: spacer(1)

  input:disabled + label
    color: $gris-neutre

  label
    cursor: pointer
    margin-bottom: 0

    .disabled
      color: $gris-neutre
      cursor: not-allowed

    & + input
      margin-left: 1em

[type=submit]
  cursor: pointer

.form-buttons
  display: flex
  justify-content: space-between
  align-items: baseline

.input-with-icon
  position: relative
  input
    display: block
  i
    position: absolute
    font-size: 20px
    right: 1rem
    top: 50%
    transform: translateY(-50%) // -50% moves the element by the half of its size to center the element
    color: $primary-color

textarea
  @include input
