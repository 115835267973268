@mixin badge
  padding: spacer(1)
  border-radius: 4px

.badge-danger
  @include badge
  @include danger

.badge-info
  @include badge
  @include info

.badge-success
  @include badge
  @include success

.badge-warning
  @include badge
  @include warning

