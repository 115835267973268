.icon-colored-text
  font-family: $title-font
  font-size: $base-size
  margin: spacer(2) 0 spacer(1) 0
  display: flex
  align-items: center

  i[class^="ph-"], i[class*=" ph-"]
    font-weight: bold
    font-size: 2.5rem
    margin-right: spacer(2)

  // fix a display bug for bank icons
  i.ph-bank, i.ph-bank-bold
    font-weight: inherit

  .icon-colored-text--bleu
    color: $bleu

  .icon-colored-text--vert
    color: $vert

  .icon-colored-text--rouge
    color: $rouge
