.row_with_chevron
  --height: 48px
  height: var(--height)
  margin-left: - spacer(2)
  margin-right: - spacer(2)
  padding: spacer(1) spacer(2)
  display: flex
  align-items: center
  justify-content: space-between
  border-top: 1px solid $gris
  cursor: pointer

  .row_with_chevron__icon
    margin-right: spacer(2)
    img
      width: var(--height)
      height: var(--height)

  .row_with_chevron__title
    flex-grow: 1
    height: var(--height)
    line-height: var(--height)
