@import '../../assets/stylesheets/shared/variables/spacing'

.left-label-input-with-unit
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-gap: var(--margin-2)
  align-items: center


  .left-label-input-with-unit__label
    margin-right: var(--margin-2)
    label
      margin-top: 0

  .left-label-input-with-unit__sub-label
    font-size: smaller

  .left-label-input-with-unit__input
    .readonly
      font-weight: bold
      border: none
      input[type="text"]
        font-weight: bold
