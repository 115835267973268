.vc-details
  &__summary
    display: flex
    list-style: none
    cursor: pointer

    &--justify-start
      justify-content: start

    &--justify-end
      justify-content: end

    &--justify-center
      justify-content: center

    i[class^="ph-"]:hover, i[class*=" ph-"]:hover,
    i[class^="ph-"]:focus, i[class*=" ph-"]:focus
      color: $blanc
      background-color: $bleu
      border-radius: 50%

    &-text
      display: flex
      align-content: center
      align-items: center
  &__alert
    dd
      text-align: justify
