:root {
  --margin-2: 0.625rem; /* 10px */
  --margin-4: 1rem; /* 16px */
  --margin-6: 1.5rem; /* 24px */
  --margin-8: 2rem; /* 32px */

  --padding-2: 0.5rem; /* 8px */
  --padding-3: 0.75rem; /* 12px */
  --padding-4: 1rem; /* 16px */
  --padding-8: 2rem; /* 32px */
}
