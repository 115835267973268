@import 'variables/colors'

/* modified from https://github.com/klaro-org/klaro-js/blob/f6a815b3ef5e96e2df5b3e3f61f71bafa540cd6c/dist/klaro.css */

.bloc .klaro,
.klaro
  font-family: $base-font
  font-size: 16px

  button
    font-family: inherit
    font-size: 18px

  &.cm-as-context-notice
    height: 100%
    width: 100%
    padding-bottom: 12px
    padding-top: 12px
    position: absolute
    box-sizing: border-box

  .context-notice
    box-sizing: border-box
    color: $noir

  .cookie-modal .cm-switch-container, .context-notice .cm-switch-container, .cookie-notice .cm-switch-container
    border-bottom-style: solid
    border-bottom-style: var(--border-style, solid)
    border-bottom-width: 1px
    border-bottom-width: var(--border-width, 1px)
    border-bottom-color: #c8c8c8
    border-bottom-color: var(--light2, #c8c8c8)
    display: block
    position: relative
    padding: 10px
    padding-left: 66px
    line-height: 20px
    vertical-align: middle
    min-height: 40px

  .cookie-modal .cm-switch-container:last-child, .context-notice .cm-switch-container:last-child, .cookie-notice .cm-switch-container:last-child
    border-bottom: 0

  .cookie-modal .cm-switch-container:first-child, .context-notice .cm-switch-container:first-child, .cookie-notice .cm-switch-container:first-child, .cookie-modal .cm-switch-container p, .context-notice .cm-switch-container p, .cookie-notice .cm-switch-container p
    margin-top: 0

  .cookie-modal .cm-switch, .context-notice .cm-switch, .cookie-notice .cm-switch
    position: relative
    display: inline-block
    width: 50px
    height: 30px

  .cookie-modal .cm-list-input:checked + .cm-list-label .slider, .context-notice .cm-list-input:checked + .cm-list-label .slider, .cookie-notice .cm-list-input:checked + .cm-list-label .slider
    background-color: $bleu

  .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider, .context-notice .cm-list-input.half-checked:checked + .cm-list-label .slider, .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider
    background-color: $bleu
    opacity: 0.6

  .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider::before, .context-notice .cm-list-input.half-checked:checked + .cm-list-label .slider::before, .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider::before
    -ms-transform: translateX(10px)
    transform: translateX(10px)

  .cookie-modal .cm-list-input.only-required + .cm-list-label .slider, .context-notice .cm-list-input.only-required + .cm-list-label .slider, .cookie-notice .cm-list-input.only-required + .cm-list-label .slider
    background-color: $noir
    opacity: 0.8

  .cookie-modal .cm-list-input.only-required + .cm-list-label .slider::before, .context-notice .cm-list-input.only-required + .cm-list-label .slider::before, .cookie-notice .cm-list-input.only-required + .cm-list-label .slider::before
    -ms-transform: translateX(10px)
    transform: translateX(10px)

  .cookie-modal .cm-list-input.required:checked + .cm-list-label .slider, .context-notice .cm-list-input.required:checked + .cm-list-label .slider, .cookie-notice .cm-list-input.required:checked + .cm-list-label .slider
    background-color: $noir
    opacity: 0.8
    cursor: not-allowed

  .cookie-modal .cm-list-input, .context-notice .cm-list-input, .cookie-notice .cm-list-input
    position: absolute
    top: 0
    left: 0
    opacity: 0
    width: 50px
    height: 30px

  .cookie-modal .cm-list-title, .context-notice .cm-list-title, .cookie-notice .cm-list-title
    font-size: 0.9em
    font-weight: 600

  .cookie-modal .cm-list-description, .context-notice .cm-list-description, .cookie-notice .cm-list-description
    color: $noir
    font-size: 0.9em
    padding-top: 4px

  .cookie-modal .cm-list-label .cm-switch, .context-notice .cm-list-label .cm-switch, .cookie-notice .cm-list-label .cm-switch
    position: absolute
    left: 0

  .cookie-modal .cm-list-label .slider, .context-notice .cm-list-label .slider, .cookie-notice .cm-list-label .slider
    background-color: $gris
    position: absolute
    cursor: pointer
    top: 0
    left: 0
    right: 0
    bottom: 0
    transition: 0.4s
    width: 50px
    display: inline-block

  .cookie-modal .cm-list-label .slider::before, .context-notice .cm-list-label .slider::before, .cookie-notice .cm-list-label .slider::before
    background-color: $gris-fonce
    position: absolute
    content: ''
    height: 20px
    width: 20px
    left: 5px
    bottom: 5px
    transition: 0.4s

  .cookie-modal .cm-list-label .slider.round, .context-notice .cm-list-label .slider.round, .cookie-notice .cm-list-label .slider.round
    border-radius: 30px

  .cookie-modal .cm-list-label .slider.round::before, .context-notice .cm-list-label .slider.round::before, .cookie-notice .cm-list-label .slider.round::before
    border-radius: 50%

  .cookie-modal .cm-list-label input:checked + .slider::before, .context-notice .cm-list-label input:checked + .slider::before, .cookie-notice .cm-list-label input:checked + .slider::before
    -ms-transform: translateX(20px)
    transform: translateX(20px)

  .cookie-modal .cm-list-input:focus + .cm-list-label .slider, .context-notice .cm-list-input:focus + .cm-list-label .slider, .cookie-notice .cm-list-input:focus + .cm-list-label .slider
    box-shadow: 0 4px 6px 0 rgba(125, 125, 125, 0.2), 5px 5px 10px 0 rgba(125, 125, 125, 0.19)

  .cookie-modal .cm-list-input:checked + .cm-list-label .slider::before, .context-notice .cm-list-input:checked + .cm-list-label .slider::before, .cookie-notice .cm-list-input:checked + .cm-list-label .slider::before
    -ms-transform: translateX(20px)
    transform: translateX(20px)

  .cookie-modal,
  .context-notice,
  .cookie-notice
    .cm-switch
      .slider
        padding-left: 0

  .cookie-modal a, .context-notice a, .cookie-notice a
    color: $bleu
    text-decoration: none

  .cookie-modal
    p, strong, h1, h2, ul, li
      color: $noir

  .context-notice
    p, strong, h1, h2, ul, li
      color: #fafafa
      color: var(--light1, #fafafa)

  .cookie-notice
    p, strong, h1, h2, ul, li
      color: $noir

  .cookie-modal
    p, h1, h2, ul, li
      display: block
      text-align: left
      margin: 0
      padding: 0
      margin-top: 0.7em

  .context-notice
    p, h1, h2, ul, li
      display: block
      text-align: left
      margin: 0
      padding: 0
      margin-top: 0.7em

  .cookie-notice
    p, h1, h2, ul, li
      display: block
      text-align: left
      margin: 0
      padding: 0
      margin-top: 0.7em

  .cookie-modal
    h1, h2, h3, h4, h5, h6
      font-family: inherit
      font-family: $title-font

  .context-notice
    h1, h2, h3, h4, h5, h6
      font-family: inherit
      font-family: $title-font

  .cookie-notice
    h1, h2, h3, h4, h5, h6
      font-family: inherit
      font-family: $title-font

  .cookie-modal .cm-link, .context-notice .cm-link, .cookie-notice .cm-link
    margin-right: 0.5em
    vertical-align: middle

  .cookie-modal .cm-btn, .context-notice .cm-btn, .cookie-notice .cm-btn
    font-family: $title-font
    text-transform: uppercase
    border: 1px solid $bleu
    color: $blanc
    background-color: $bleu-dark
    border-radius: 4px
    border-radius: var(--border-radius, 4px)
    padding: 6px 10px
    margin-right: 0.5em
    border-style: none
    padding: 0.4em
    font-size: 1em
    cursor: pointer

  .cookie-modal .cm-btn:disabled, .context-notice .cm-btn:disabled, .cookie-notice .cm-btn:disabled
    opacity: 0.5

  .cookie-modal .cm-btn.cm-btn-close, .context-notice .cm-btn.cm-btn-close, .cookie-notice .cm-btn.cm-btn-close
    background-color: #c8c8c8
    background-color: var(--light2, #c8c8c8)

  .cookie-modal .cm-btn.cm-btn-success, .context-notice .cm-btn.cm-btn-success, .cookie-notice .cm-btn.cm-btn-success
    background-color: $bleu
    border-radius: 4px

  .cookie-modal .cm-btn.cm-btn-success-var, .context-notice .cm-btn.cm-btn-success-var, .cookie-notice .cm-btn.cm-btn-success-var
    background-color: #24cc9a
    background-color: var(--green2, #24cc9a)

  .cookie-modal .cm-btn.cm-btn-info, .context-notice .cm-btn.cm-btn-info, .cookie-notice .cm-btn.cm-btn-info
    background-color: $bleu-dark
    border-radius: 4px

  .context-notice
    border-radius: 4px
    border-radius: var(--border-radius, 4px)
    border-style: solid
    border-style: var(--border-style, solid)
    border-width: 1px
    border-width: var(--border-width, 1px)
    border-color: #c8c8c8
    border-color: var(--light2, #c8c8c8)
    background-color: #fafafa
    background-color: var(--light1, #fafafa)
    display: flex
    flex-direction: column
    flex-wrap: wrap
    align-items: center
    justify-content: center
    padding: 12px
    height: 100%

    &.cm-dark
      background-color: #333
      background-color: var(--dark1, #333)
      border-color: #5c5c5c
      border-color: var(--dark2, #5c5c5c)

      p
        color: #fafafa
        color: var(--light1, #fafafa)

        a
          color: #459cdc
          color: var(--blue2, #459cdc)

    p
      color: #333
      color: var(--dark1, #333)
      flex-grow: 0
      text-align: center
      padding-top: 0
      margin-top: 0

      a
        color: #24cc9a
        color: var(--green2, #24cc9a)

      &.cm-buttons
        margin-top: 12px

  .cookie-modal
    width: 100%
    height: 100%
    position: fixed
    overflow: hidden
    left: 0
    top: 0
    z-index: 1000

    &.cm-embedded
      position: relative
      height: inherit
      width: inherit
      left: inherit
      right: inherit
      z-index: 0

      .cm-modal.cm-klaro
        position: relative
        -ms-transform: none
        transform: none

    .cm-bg
      background: rgba(0, 0, 0, 0.5)
      height: 100%
      width: 100%
      position: fixed
      top: 0
      left: 0

    .cm-modal
      &.cm-klaro
        background-color: $blanc
        color: #fafafa
        color: var(--light1, #fafafa)
        z-index: 1001
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19)
        width: 100%
        max-height: 98%
        top: 50%
        -ms-transform: translateY(-50%)
        transform: translateY(-50%)
        position: fixed
        overflow: auto

      .hide
        border-style: none
        background: none
        cursor: pointer
        position: absolute
        top: 20px
        right: 20px
        z-index: 1

        svg
          stroke: black

      .cm-footer
        border-top-color: #5c5c5c
        border-top-color: var(--dark2, #5c5c5c)
        border-top-width: 1px
        border-top-width: var(--border-width, 1px)
        border-top-style: solid
        border-top-style: var(--border-style, solid)
        padding: 1em

      .cm-footer-buttons
        display: flex
        flex-flow: row
        justify-content: space-between

      .cm-footer .cm-powered-by
        font-size: 0.8em
        padding-top: 4px
        text-align: right
        padding-right: 8px

        a
          color: #5c5c5c
          color: var(--dark2, #5c5c5c)

      .cm-header
        border-bottom-width: 1px
        border-bottom-width: var(--border-width, 1px)
        border-bottom-style: solid
        border-bottom-style: var(--border-style, solid)
        border-bottom-color: #5c5c5c
        border-bottom-color: var(--dark2, #5c5c5c)
        padding: 1em
        padding-right: 24px

        h1
          margin: 0
          font-size: 2em
          display: block

          &.title
            padding-right: 20px
            font-family: $title-font
            text-transform: uppercase

      .cm-body
        padding: 1em

        ul
          display: block

        span
          display: inline-block
          width: auto

        ul
          &.cm-services, &.cm-purposes
            padding: 0
            margin: 0

          &.cm-services li.cm-purpose .cm-services .cm-caret, &.cm-purposes li.cm-purpose .cm-services .cm-caret
            color: #a0a0a0
            color: var(--light3, #a0a0a0)

          &.cm-services li.cm-purpose .cm-services .cm-content, &.cm-purposes li.cm-purpose .cm-services .cm-content
            margin-left: -40px
            display: none

          &.cm-services li.cm-purpose .cm-services .cm-content.expanded, &.cm-purposes li.cm-purpose .cm-services .cm-content.expanded
            margin-top: 10px
            display: block

          &.cm-services li
            &.cm-service, &.cm-purpose
              position: relative
              line-height: 20px
              vertical-align: middle
              padding-left: 60px
              min-height: 40px

          &.cm-purposes li
            &.cm-service, &.cm-purpose
              position: relative
              line-height: 20px
              vertical-align: middle
              padding-left: 60px
              min-height: 40px

          &.cm-services li
            &.cm-service:first-child, &.cm-purpose:first-child
              margin-top: 0

          &.cm-purposes li
            &.cm-service:first-child, &.cm-purpose:first-child
              margin-top: 0

          &.cm-services li
            &.cm-service p, &.cm-purpose p
              margin-top: 0

          &.cm-purposes li
            &.cm-service p, &.cm-purpose p
              margin-top: 0

          &.cm-services li
            &.cm-service p.purposes, &.cm-purpose p.purposes
              color: #a0a0a0
              color: var(--light3, #a0a0a0)
              font-size: 0.8em

          &.cm-purposes li
            &.cm-service p.purposes, &.cm-purpose p.purposes
              color: #a0a0a0
              color: var(--light3, #a0a0a0)
              font-size: 0.8em

          &.cm-services li
            &.cm-service.cm-toggle-all, &.cm-purpose.cm-toggle-all
              border-top-width: 1px
              border-top-width: var(--border-width, 1px)
              border-top-style: solid
              border-top-style: var(--border-style, solid)
              border-top-color: #5c5c5c
              border-top-color: var(--dark2, #5c5c5c)
              padding-top: 1em

          &.cm-purposes li
            &.cm-service.cm-toggle-all, &.cm-purpose.cm-toggle-all
              border-top-width: 1px
              border-top-width: var(--border-width, 1px)
              border-top-style: solid
              border-top-style: var(--border-style, solid)
              border-top-color: #5c5c5c
              border-top-color: var(--dark2, #5c5c5c)
              padding-top: 1em

          &.cm-services li
            &.cm-service span.cm-list-title, &.cm-purpose span.cm-list-title
              font-weight: 600

          &.cm-purposes li
            &.cm-service span.cm-list-title, &.cm-purpose span.cm-list-title
              font-weight: 600

          &.cm-services li
            &.cm-service span
              &.cm-opt-out, &.cm-required
                color: #5c5c5c
                color: var(--dark2, #5c5c5c)
                padding-left: 0.2em
                font-size: 0.8em

            &.cm-purpose span
              &.cm-opt-out, &.cm-required
                color: #5c5c5c
                color: var(--dark2, #5c5c5c)
                padding-left: 0.2em
                font-size: 0.8em

          &.cm-purposes li
            &.cm-service span
              &.cm-opt-out, &.cm-required
                color: #5c5c5c
                color: var(--dark2, #5c5c5c)
                padding-left: 0.2em
                font-size: 0.8em

            &.cm-purpose span
              &.cm-opt-out, &.cm-required
                color: #5c5c5c
                color: var(--dark2, #5c5c5c)
                padding-left: 0.2em
                font-size: 0.8em

  .cookie-notice:not(.cookie-modal-notice)
    background-color: $blanc
    z-index: 999
    position: fixed
    width: 100%
    bottom: 0
    right: 0

    &.cn-embedded
      position: relative
      height: inherit
      width: inherit
      left: inherit
      right: inherit
      bottom: inherit
      z-index: 0

      .cn-body
        padding-top: 0.5em

    .cn-body
      margin-bottom: 0
      margin-right: 0
      bottom: 0
      padding: 1em
      padding-top: 0

      p
        margin-bottom: 0.5em

        &.cn-changes
          text-decoration: underline

      .cn-learn-more
        display: inline-block
        flex-grow: 1

      .cn-buttons
        display: inline-block
        margin-top: -0.5em

        button.cm-btn
          margin-top: 0.5em

      .cn-ok
        margin-top: -0.5em
        display: flex
        flex-flow: row
        flex-wrap: wrap
        justify-content: right
        align-items: baseline

        a, div
          margin-top: 0.5em

  .cookie-modal-notice
    box-sizing: border-box
    background-color: $blanc
    color: #fafafa
    color: var(--light1, #fafafa)
    z-index: 1001
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19)
    width: 100%
    max-height: 98%
    top: 50%
    -ms-transform: translateY(-50%)
    transform: translateY(-50%)
    position: fixed
    overflow: auto
    padding: 1em
    padding-top: 0.2em

    .title
      font-size: 1.5em
      font-family: $title-font
      margin-bottom: 0.5em
      text-transform: uppercase
      font-weight: normal

    .cn-ok
      display: flex
      flex-flow: row
      justify-content: space-between
      align-items: center
      margin-top: 2em

  .cookie-notice-hidden
    display: none !important

@media (min-width: 660px)
  .klaro .cookie-modal .cm-modal.cm-klaro
    border-radius: 4px
    border-radius: var(--border-radius, 4px)
    position: relative
    margin: 0 auto
    max-width: 640px
    height: auto
    width: auto

@media (min-width: 1024px)
  .klaro .cookie-notice:not(.cookie-modal-notice)
    border-radius: 4px
    border-radius: var(--border-radius, 4px)
    position: fixed
    position: var(--notice-position, fixed)
    right: 20px
    right: var(--notice-right, 20px)
    left: auto
    left: var(--notice-left, auto)
    bottom: 20px
    bottom: var(--notice-bottom, 20px)
    top: auto
    top: var(--notice-top, auto)
    max-width: 400px
    max-width: var(--notice-max-width, 400px)
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19)

@media (max-width: 1023px)
  .klaro .cookie-notice:not(.cookie-modal-notice)
    border-style: none
    border-radius: 0

@media (max-width: 384px)
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons
    width: 100%

@media (max-width: 384px)
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn
    width: calc(50% - 0.5em)

@media (min-width: 400px)
  .klaro .cookie-modal-notice
    border-radius: 4px
    border-radius: var(--border-radius, 4px)
    position: relative
    margin: 0 auto
    max-width: 400px
    height: auto
    width: auto
