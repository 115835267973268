// Downloaded from https://github.com/PhilippeMarcMeyer/vanillaSelectBox

.hidden-search
  display: none !important

li[data-parent]
  &.closed
    display: none !important

  &.open:not(.hidden-search)
    display: block !important

.vsb-js-search-zone
  background-color: #fff

  input
    @include input

li
  &.disabled, &.overflow
    cursor: not-allowed
    opacity: 0.3
    background-color: #999

  &.short
    overflow: hidden
    text-overflow: ellipsis

.vsb-main
  width: 100%
  position: relative

  button
    text-align: left

    &.disabled
      cursor: not-allowed
      opacity: 0.65

  .title
    margin-right: 6px
    user-select: none

  li:hover
    color: #245891

  ul
    white-space: normal

.vsb-menu
  @include dropdown-container
  z-index: 1000
  visibility: hidden

  ul
    user-select: none
    list-style: none
    color: #333
    cursor: pointer
    overflow-y: auto
    padding-inline-start: 0 /* Resets the user-agent stylesheet for Chrome on Mac OS */
    margin-block: 0 /* Resets the user-agent stylesheet for Chrome on Mac OS */


  li
    @include hover-highlight
    min-height: 1.4em
    padding: 7px 2em 7px 0
    position: relative

    &.grouped-option
      b
        display: inline-block
        font-size: 15px
        margin-left: 10px
        transform: translate(-18px)

      &.open span
        display: inline-block
        font-size: inherit
        margin-top: -2px
        height: 8px
        width: 8px
        transform: translate(-38px) rotate(45deg)
        border-bottom: 3px solid black
        border-right: 3px solid black
        border-radius: 2px

      &.closed span
        display: inline-block
        font-size: inherit
        height: 8px
        width: 8px
        transform: translate(-38px) rotate(-45deg)
        border-bottom: 3px solid black
        border-right: 3px solid black
        border-radius: 2px

      i
        display: inline-block
        font-size: inherit
        float: left
        font-weight: bold
        margin-left: 22px
        margin-right: 2px
        height: 11px
        width: 8px
        border: 1px solid
        border-radius: 3px
        padding: 1px 3px 2px 3px
        margin-top: 0px
        color: black

      &.checked i::after
        content: ""
        display: inline-block
        font-size: inherit
        color: #333
        float: left
        margin-left: 0px
        display: inline-block
        transform: rotate(45deg)
        height: 8px
        width: 5px
        border-bottom: 3px solid black
        border-right: 3px solid black

  \:not(.multi) li
    @include active-checkbox

  .multi li
    min-height: 1.4em
    padding: 7px 0 7px 26px

    &.grouped-option
      font-size: 15px
      padding-left: 5px

      &:hover
        font-weight: bold
        text-decoration: underline
        color: #245891

    &:not(.grouped-option)
      &::before
        content: ""
        display: inline-block
        font-size: inherit
        float: left
        font-weight: bold
        margin-left: -22px
        margin-right: 2px
        border: 2px solid
        border-radius: 3px
        padding: 7px
        margin-top: 0px
        color: #005D82

      &.active
        &::before
          background-color: #005D82

        /* The checkboxes are made with a tilted border */
        &::after
          content: ""
          display: inline-block
          font-size: inherit
          color: #333
          float: left
          margin-left: -18px
          display: inline-block
          transform: rotate(45deg)
          margin-top: 1px
          height: 11px
          width: 5px
          border-bottom: 3px solid white
          border-right: 3px solid white
          border-radius: 2px
          position: absolute
          top: 6px
          left: 27.5px

li[data-parent]
  padding-left: 50px !important
