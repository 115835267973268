$button-border-radius: 4px

@mixin icon-effect
  i
    @include transition
    margin-right: 10px

  &:hover
    i
      margin-left: 3px
      margin-right: 7px

@mixin btn-large
  font-size: 18px
  padding: 20px spacer(3)

@mixin btn-small
  font-size: 14px
  padding: spacer(1) spacer(2)

@mixin btn-block
  display: block
  text-align: center
  width: 100%
  box-sizing: border-box

@mixin undo-btn-block
  display: inline-block
  text-align: inherit
  width: auto

.btn
  @include transition
  display: inline-block
  text-decoration: none
  border-radius: $button-border-radius
  line-height: spacer(4)
  cursor: pointer

  i
    vertical-align: middle

  &[disabled]
    opacity: 0.3
    cursor: inherit

  &.icon-left
    i
      margin-right: spacer(1)

  &.icon-right
    i
      margin-left: spacer(1)

.btn-primary
  @extend .btn
  @include btn-large
  font-family: $title-font
  text-transform: uppercase
  background-color: $primary-color
  color: $blanc
  border: 1px solid $primary-color

  &:hover
    background-color: $primary-color-hover
    border: 1px solid $primary-color-hover

  @include icon-effect

.btn-bleu
  @extend .btn-primary
  background-color: $bleu
  color: $blanc
  border: 1px solid $bleu

  &:hover
    background-color: $bleu-dark
    border: 1px solid $bleu-dark

.btn-rouge
  @extend .btn-primary
  background-color: $rouge
  color: $blanc
  border: 1px solid $rouge

  &:hover
    background-color: $rouge-dark
    border: 1px solid $rouge-dark

.btn-jaune
  @extend .btn-primary
  background-color: $jaune
  color: $blanc
  border: 1px solid $jaune

  &:hover
    background-color: $jaune-dark
    border: 1px solid $jaune-dark

.btn-vert
  @extend .btn-primary
  background-color: $vert
  color: $blanc
  border: 1px solid $vert

  &:hover
    background-color: $vert-dark
    border: 1px solid $vert-dark

.btn-blanc
  @extend .btn-primary
  background-color: $blanc
  color: $blanc-dark
  border: 1px solid $blanc

  &:hover
    background-color: $blanc-dark
    color: $blanc
    border: 1px solid $blanc-dark

.btn-turquoise
  @extend .btn-primary
  background-color: $turquoise
  color: $blanc
  border: 1px solid $turquoise

  &:hover
    background-color: $turquoise-dark
    border: 1px solid $turquoise-dark

.btn-outline
  @extend .btn
  @include btn-large
  font-family: $title-font
  text-transform: uppercase
  background: none
  color: $noir
  border: 1px solid $gris

  &:hover
    color: $primary-color-hover
    border-color: $primary-color-hover

  @include icon-effect

.btn-secondary
  @extend .btn
  @extend .ui-s-reg
  border: 1px solid $gris
  padding: 3px 11px
  color: $noir
  min-width: 16px
  &:not(.bg-blanc)
    background: none
  &:hover
    color: $primary-color-hover
    border-color: $primary-color-hover
  &.current
    background-color: $bleu-light
    border-color: $primary-color-hover
    color: $primary-color-hover
    cursor: default
  &--reverse
    &:hover
      background-color: $noir
      color: $blanc
      border-color: $blanc-deep

  i
    font-size: 16px
    vertical-align: text-top

  &-rouge
    @extend .btn-secondary
    color: $rouge
    &:hover
      color: $rouge-dark
      border-color: $rouge-dark
    &--reverse
      &:hover
        background-color: $rouge-dark
        color: $rouge-xlight
        border-color: $rouge

  &-jaune
    @extend .btn-secondary
    color: $jaune
    &:hover
      color: $jaune-dark
      border-color: $jaune-dark
    &--reverse
      &:hover
        background-color: $jaune-dark
        color: $jaune-xlight
        border-color: $jaune

  &-vert
    @extend .btn-secondary
    color: $vert
    &:hover
      color: $vert-dark
      border-color: $vert-dark
    &--reverse
      &:hover
        background-color: $vert-dark
        color: $vert-xlight
        border-color: $vert

  &-blanc
    @extend .btn-secondary
    color: $blanc-light
    &:hover
      color: $blanc-dark
      border-color: $blanc-dark
    &--reverse
      color: $blanc
      &:hover
        background-color: $blanc
        color: $blanc-dark
        border-color: $blanc-light

  &-turquoise
    @extend .btn-secondary
    color: $turquoise
    transition: color $transition-duration
    &:hover
      color: $turquoise-dark
      border-color: $turquoise-dark
    &--reverse
      &:hover
        background-color: $turquoise-dark
        color: $turquoise-xlight
        border-color: $turquoise

  &-bleu
    @extend .btn-secondary
    color: $bleu
    transition: color $transition-duration
    &:hover
      color: $bleu-dark
      border-color: $bleu-dark
    &--reverse
      &:hover
        background-color: $bleu-dark
        color: $bleu-xlight
        border-color: $bleu

  &-bleu-fonce
    @extend .btn-secondary
    color: $bleu-fonce
    transition: color $transition-duration
    &:hover
      color: $bleu-fonce-dark
      border-color: $bleu-fonce-dark
    &--reverse
      &:hover
        background-color: $bleu-fonce-dark
        color: $bleu-fonce-xlight
        border-color: $bleu-fonce

.btn-large
  @include btn-large

.btn-small
  @include btn-small

.link
  color: $primary-color
  transition: color $transition-duration
  &:hover
    color: $primary-color-hover
    text-decoration: underline
    text-decoration-thickness: 2px
  &-secondary
    @extend .link
    color: $blanc
    &:hover
      color: $blanc

  i
    vertical-align: top
    font-size: inherit

    &[class^="ph-"], [class*=" ph-"]
      display: inline-flex
      vertical-align: text-top

  @include icon-effect

.link-underline
  @extend .link
  text-decoration: underline
  &:hover
    text-decoration-thickness: 2px

.link-no-animation
  transition: none
  i
    margin-left: 0
    margin-right: 0
    transition: none
  &:hover
    i
      margin-left: 0
      margin-right: 0

.btn-block-xs, .btn-block-sm
  @include btn-block

@media #{$breakpoint-larger-than-sm-768px}
  .btn-block-xs
    @include undo-btn-block

@media #{$breakpoint-larger-than-md-992px}
  .btn-block-sm
    @include undo-btn-block
