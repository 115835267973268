@media #{$breakpoint-smaller-than-md-992px}
  .grid
    &.grid-cols-none-xs
      grid-template-columns: repeat(2, 1fr)
      .grid-1, .grid-2, .grid-3
        grid-column: inherit

@media #{$breakpoint-smaller-than-sm-768px}
  .grid
    display: grid
    grid-template-columns: repeat(1, 1fr)
    grid-gap: 0 spacer(2)
    grid-auto-rows: minmax(100px, auto)

    &.grid-cols-none-xs
      grid-template-columns: none
      .grid-1, .grid-2, .grid-3
        grid-column: inherit

@media #{$breakpoint-larger-than-sm-768px}
  .grid
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 0 spacer(3)
    grid-auto-rows: minmax(100px, auto)

    .grid-1
      grid-column: 1
    .grid-2
      grid-column: 2
    .grid-3
      grid-column: 3

    .grid-row-1
      grid-row: 1

    & > *
      display: flex
      flex-flow: column nowrap

  /* Page services énergétiques */
  .grid-se
    > div:nth-child(1),
    > div:nth-child(7)
      grid-row: span 2

  /* Page accueil espace client */
  .welcome-grid
    > div:nth-child(5)
      grid-column: 2
      grid-row: 2 / 4
