// Dowloaded from http://flexboxgrid.com/

$row-margin: -1rem
$container-fluid-spacing-lg: spacer(9)
$container-max-width: 1600px

@mixin container-fluid-padding-xs
  padding-right: spacer(2)
  padding-left: spacer(2)

@mixin container-fluid-padding-md
  padding-right: spacer(4)
  padding-left: spacer(4)

@mixin container-fluid-padding-lg
  padding-right: $container-fluid-spacing-lg
  padding-left: $container-fluid-spacing-lg

.container-fluid
  margin-right: auto
  margin-left: auto
  max-width: $container-max-width
  @include container-fluid-padding-xs
  @media #{$breakpoint-larger-than-md-992px}
    @include container-fluid-padding-md
  @media #{$breakpoint-larger-than-lg-1200px}
    @include container-fluid-padding-lg

.row
  box-sizing: border-box
  display: -ms-flexbox
  display: -webkit-box
  display: flex
  -ms-flex: 0 1 auto
  -webkit-box-flex: 0
  flex: 0 1 auto
  -ms-flex-direction: row
  -webkit-box-orient: horizontal
  -webkit-box-direction: normal
  flex-direction: row
  -ms-flex-wrap: wrap
  flex-wrap: wrap
  margin-right: $row-margin
  margin-left: $row-margin

.row.reverse
  -ms-flex-direction: row-reverse
  -webkit-box-orient: horizontal
  -webkit-box-direction: reverse
  flex-direction: row-reverse

.col.reverse
  -ms-flex-direction: column-reverse
  -webkit-box-orient: vertical
  -webkit-box-direction: reverse
  flex-direction: column-reverse

@mixin col-size($type, $index, $size)
  .col-#{$type}-#{$index}
    -ms-flex-preferred-size: $size
    flex-basis: $size
    max-width: $size
  .col-#{$type}-offset-#{$index}
    margin-left: $size

@mixin col-content($type)
  .col-#{$type},
  .col-#{$type}-1,
  .col-#{$type}-2,
  .col-#{$type}-3,
  .col-#{$type}-4,
  .col-#{$type}-5,
  .col-#{$type}-6,
  .col-#{$type}-7,
  .col-#{$type}-8,
  .col-#{$type}-9,
  .col-#{$type}-10,
  .col-#{$type}-11,
  .col-#{$type}-12
    box-sizing: border-box
    -ms-flex: 0 0 auto
    -webkit-box-flex: 0
    flex: 0 0 auto
    padding-right: 1rem
    padding-left: 1rem

  .col-#{$type}
    -webkit-flex-grow: 1
    -ms-flex-positive: 1
    -webkit-box-flex: 1
    flex-grow: 1
    -ms-flex-preferred-size: 0
    flex-basis: 0
    max-width: 100%
  @include col-size($type, '0', 0%)
  @include col-size($type, '1', 8.333%)
  @include col-size($type, '2', 16.666%)
  @include col-size($type, '2-4', 20%)
  @include col-size($type, '2-5', 20.8335%)
  @include col-size($type, '3', 25%)
  @include col-size($type, '4', 33.333%)
  @include col-size($type, '5', 41.667%)
  @include col-size($type, '6', 50%)
  @include col-size($type, '7', 58.333%)
  @include col-size($type, '8', 66.667%)
  @include col-size($type, '9', 75%)
  @include col-size($type, '10', 83.333%)
  @include col-size($type, '11', 91.667%)
  @include col-size($type, '12', 100%)

  .start-#{$type}
    -ms-flex-pack: start
    -webkit-box-pack: start
    justify-content: flex-start
    text-align: start

  .center-#{$type}
    -ms-flex-pack: center
    -webkit-box-pack: center
    justify-content: center
    text-align: center

  .end-#{$type}
    -ms-flex-pack: end
    -webkit-box-pack: end
    justify-content: flex-end
    text-align: end

  .top-#{$type}
    -ms-flex-align: start
    -webkit-box-align: start
    align-items: flex-start

  .middle-#{$type}
    -ms-flex-align: center
    -webkit-box-align: center
    align-items: center

  .bottom-#{$type}
    -ms-flex-align: end
    -webkit-box-align: end
    align-items: flex-end

  .around-#{$type}
    -ms-flex-pack: distribute
    justify-content: space-around

  .between-#{$type}
    -ms-flex-pack: justify
    -webkit-box-pack: justify
    justify-content: space-between

  .first-#{$type}
    -ms-flex-order: -1
    -webkit-box-ordinal-group: 0
    order: -1

  .last-#{$type}
    -ms-flex-order: 1
    -webkit-box-ordinal-group: 2
    order: 1

@include col-content('xs')

@media #{$breakpoint-larger-than-sm-768px}
  .container
    width: 46rem
  @include col-content('sm')

@media #{$breakpoint-larger-than-md-992px}
  .container
    width: 61rem
  @include col-content('md')

@media #{$breakpoint-larger-than-lg-1200px}
  .container
    width: 71rem
  @include col-content('lg')

@media #{$breakpoint-larger-than-xl-1600px}
  .container
    width: 90rem
  @include col-content('xl')
