$spinner-color: black !default
$spinner-opacity: 0.5 !default

.spinner
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

  z-index: 5
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0

  color: $spinner-color

  background-color: rgba(white, $spinner-opacity)

  opacity: 0
  animation: fade-in .15s .15s linear forwards

  &:not(.active)
    display: none

  > *:not(:last-child)
    margin-bottom: 1em

  .spinner-icon
    font-size: 3rem
  .spinner-message
    font-size: 2rem

@keyframes fade-in
  100%
    opacity: 1

//Spinning animation
.spin
  animation: icon-spin 3s infinite linear

@keyframes icon-spin
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)
