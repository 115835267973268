// Do not use commented colors without design team's validation

// Vert
$fruit-salad-50: #f6f9f4;
// $fruit-salad-100: #e7f3e5;
$fruit-salad-200: #d0e6cc;
// $fruit-salad-300: #aad2a3;
// $fruit-salad-400: #7cb573;
$fruit-salad-500: #5b9a51;
$fruit-salad-600: #467c3d;
$fruit-salad-700: #396233;
// $fruit-salad-800: #314f2c;
// $fruit-salad-900: #294126;
// $fruit-salad-950: #122310;

// Turquoise
$keppel-50: #f2fbf9;
// $keppel-100: #d3f4ec;
$keppel-200: #a6e9d9;
// $keppel-300: #72d6c2;
$keppel-400: #40b29f;
// $keppel-500: #2ba18f;
$keppel-600: #208174;
$keppel-700: #1e675f;
// $keppel-800: #1c534d;
// $keppel-900: #1b4641;
// $keppel-950: #0a2927;

// Rouge
$mandy-50: #fef2f2;
// $mandy-100: #fce7e7;
$mandy-200: #f9d2d5;
// $mandy-300: #f4adb2;
// $mandy-400: #ed7f88;
$mandy-500: #e15060;
$mandy-600: #cd3149;
$mandy-700: #ac243d;
// $mandy-800: #902139;
// $mandy-900: #7c1f36;
// $mandy-950: #450c19;

// Jaune
$yellow-sea-50: #fffdea;
// $yellow-sea-100: #fff7c5;
$yellow-sea-200: #ffee85;
// $yellow-sea-300: #ffdf46;
// $yellow-sea-400: #ffcd1b;
$yellow-sea-500: #f7a600;
$yellow-sea-600: #e28200;
$yellow-sea-700: #bb5a02;
// $yellow-sea-800: #984508;
// $yellow-sea-900: #7c390b;
// $yellow-sea-950: #481c00;

// Brun
$nutmeg-50: #fbf7f1;
// $nutmeg-100: #f5ebdf;
$nutmeg-200: #ead4be;
// $nutmeg-300: #ddb694;
// $nutmeg-400: #ce9269;
// $nutmeg-500: #c4784b;
// $nutmeg-600: #b66340;
// $nutmeg-700: #984f36;
$nutmeg-800: #804434;
$nutmeg-900: #63372b;
$nutmeg-950: #351b15;

// Bleu
$steel-blue-50: #f3f6fc;
// $steel-blue-100: #e6edf8;
$steel-blue-200: #c7daf0;
// $steel-blue-300: #96bae3;
// $steel-blue-400: #5e96d2;
$steel-blue-500: #3978bb;
$steel-blue-600: #295fa0;
$steel-blue-700: #234d81;
// $steel-blue-800: #20416c;
// $steel-blue-900: #20395a;
// $steel-blue-950: #15243c;

// Blanc
$white-50: #ffffff;
$white-100: #efefef;
$white-200: #dcdcdc;
$white-300: #bdbdbd;
$white-400: #989898;
// $white-500: #7c7c7c;
$white-600: #656565;
$white-700: #525252;
// $white-800: #464646;
// $white-900: #3d3d3d;
$white-950: #292929;

// Beige
$stark-white-50: #f9f7f3;
$stark-white-100: #f1ece3;
$stark-white-200: #ddd1bc;
// $stark-white-300: #cfbea2;
$stark-white-400: #bb9e7c;
// $stark-white-500: #ad8962;
// $stark-white-600: #a07656;
// $stark-white-700: #856049;
// $stark-white-800: #6d503f;
// $stark-white-900: #594235;
// $stark-white-950: #2f211b;
