@font-face
  font-family: 'Anton'
  src: url('fonts/Anton/Anton-Regular.woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Roboto'
  src: url('fonts/Roboto/Roboto-Regular.woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Roboto'
  src: url('fonts/Roboto/Roboto-Bold.woff')
  font-weight: bold
  font-style: normal

@font-face
  font-family: 'Roboto'
  src: url('fonts/Roboto/Roboto-Black.woff')
  font-weight: 900
  font-style: normal

@font-face
  font-family: 'Roboto'
  src: url('fonts/Roboto/Roboto-Medium.woff')
  font-weight: 500
  font-style: normal
