@mixin nav-links
  a
    color: $noir
    transition: color $transition-duration
    &:hover
      color: $primary-color
    &.active
      color: $primary-color

.navigation-desktop
  @extend .ui-m-med
  @include nav-links
  position: relative
  display: flex
  flex-wrap: nowrap
  align-items: center
  background: white
  padding: spacer(2)

  .navigation-main-links
    display: flex
    margin: 0 spacer(3)

    a
      margin-right: spacer(2)
      white-space: nowrap
      &:last-child
        margin-right: 0

.navigation-right
  margin-left: auto

.navbar-logo
  width: 122px

// Navigation Mobile
.navigation-mobile
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: $blanc
  overflow-y: auto
  align-items: baseline
  justify-content: center
  z-index: 5
  transition: all .5s ease

  &:not(.active)
    left: 100%

  a
    display: block
    margin-bottom: spacer(3)
    &:last-child
      margin-bottom: 0

  .navigation-main-links
    @extend .ui-m-med

  .navigation-mobile-content,.navigation-mobile-header
    @include nav-links
    padding: spacer(2)
    width: calc(100% - 32px) // 32px is equal to paddind spacer(2)*2 for padding right and left

  .navigation-mobile-content
    display: flex
    flex-direction: column
    justify-content: space-between
    height: calc(80vh - 32px - 24px - 32px) //(100-20vh) for preheader height - 32px for padding - 24px for header height - 32px for margin-bottom header

  .navigation-mobile-header
    height: 24px // fix the height in order to calculate easily the mobile-content height. 24px is the icon size.
