$icomoon-font-family: "Phosphor" !default;
$icomoon-font-path: "fonts" !default;

$ph-facebook-enercoop: unquote('"\\e900"');
$ph-habitation-appartement-enercoop: unquote('"\\e901"');
$ph-habitation-autres-enercoop: unquote('"\\e902"');
$ph-habitation-cheminee-enercoop: unquote('"\\e903"');
$ph-habitation-comble-enercoop: unquote('"\\e904"');
$ph-habitation-etages-enercoop: unquote('"\\e905"');
$ph-habitation-garage-isole-enercoop: unquote('"\\e906"');
$ph-habitation-garage-enercoop: unquote('"\\e907"');
$ph-habitation-isolation-bonne-enercoop: unquote('"\\e908"');
$ph-habitation-isolation-mauvaise-enercoop: unquote('"\\e909"');
$ph-habitation-isolation-moyenne-enercoop: unquote('"\\e90a"');
$ph-habitation-locataire-enercoop: unquote('"\\e90b"');
$ph-habitation-maison-enercoop: unquote('"\\e90c"');
$ph-habitation-mitoyen-enercoop: unquote('"\\e90d"');
$ph-habitation-proprietaire-enercoop: unquote('"\\e90e"');
$ph-habitation-secondaire-enercoop: unquote('"\\e90f"');
$ph-habitation-veranda-enercoop: unquote('"\\e910"');
$ph-instagram-enercoop: unquote('"\\e911"');
$ph-installation-autre-non-electrique-enercoop: unquote('"\\e912"');
$ph-installation-bois-enercoop: unquote('"\\e913"');
$ph-installation-chaudiere-gaz-enercoop: unquote('"\\e914"');
$ph-installation-chauffe-eau-thermo-enercoop: unquote('"\\e915"');
$ph-installation-chauffe-eau-enercoop: unquote('"\\e916"');
$ph-installation-collectif-enercoop: unquote('"\\e917"');
$ph-installation-electrique-enercoop: unquote('"\\e918"');
$ph-installation-fioul-enercoop: unquote('"\\e919"');
$ph-installation-gaz-enercoop: unquote('"\\e91a"');
$ph-installation-pac-hybride-enercoop: unquote('"\\e91b"');
$ph-installation-pac-enercoop: unquote('"\\e91c"');
$ph-installation-solaire-conso-totale-enercoop: unquote('"\\e91d"');
$ph-installation-solaire-prise-electrique-enercoop: unquote('"\\e91e"');
$ph-installation-solaire-revente-surplus-enercoop: unquote('"\\e91f"');
$ph-installation-solaire-revente-totale-enercoop: unquote('"\\e920"');
$ph-ko-enercoop: unquote('"\\e921"');
$ph-link-enercoop: unquote('"\\e922"');
$ph-linkedin-enercoop: unquote('"\\e923"');
$ph-logement-aquarium-enercoop: unquote('"\\e924"');
$ph-logement-aspirateur-enercoop: unquote('"\\e925"');
$ph-logement-autre-hifi-enercoop: unquote('"\\e926"');
$ph-logement-autre-enercoop: unquote('"\\e927"');
$ph-logement-bouilloire-enercoop: unquote('"\\e928"');
$ph-logement-brasseur-air-enercoop: unquote('"\\e929"');
$ph-logement-cave-a-vin-enercoop: unquote('"\\e92a"');
$ph-logement-centrale-vapeur-enercoop: unquote('"\\e92b"');
$ph-logement-chambre-enercoop: unquote('"\\e92c"');
$ph-logement-climatisation-enercoop: unquote('"\\e92d"');
$ph-logement-congelateur-enercoop: unquote('"\\e92e"');
$ph-logement-fer-a-repasser-enercoop: unquote('"\\e92f"');
$ph-logement-four-encastre-enercoop: unquote('"\\e930"');
$ph-logement-four-gaz-enercoop: unquote('"\\e931"');
$ph-logement-frigo-americain-enercoop: unquote('"\\e932"');
$ph-logement-frigo-congelateur-enercoop: unquote('"\\e933"');
$ph-logement-frigo-enercoop: unquote('"\\e934"');
$ph-logement-grille-pain-enercoop: unquote('"\\e935"');
$ph-logement-halogen-enercoop: unquote('"\\e936"');
$ph-logement-lave-linge-enercoop: unquote('"\\e937"');
$ph-logement-lave-vaisselle-enercoop: unquote('"\\e938"');
$ph-logement-led-enercoop: unquote('"\\e939"');
$ph-logement-machine-expresso-enercoop: unquote('"\\e93a"');
$ph-logement-marmite-enercoop: unquote('"\\e93b"');
$ph-logement-micro-onde-enercoop: unquote('"\\e93c"');
$ph-logement-mini-four-enercoop: unquote('"\\e93d"');
$ph-logement-partie-de-semaine-partie-de-journee-enercoop: unquote('"\\e93e"');
$ph-logement-partie-de-semaine-toute-la-journee-enercoop: unquote('"\\e93f"');
$ph-logement-piscine-chauffee-enercoop: unquote('"\\e940"');
$ph-logement-recharge-scooter-enercoop: unquote('"\\e941"');
$ph-logement-recharge-trotinette-enercoop: unquote('"\\e942"');
$ph-logement-robot-enercoop: unquote('"\\e943"');
$ph-logement-seche-linge-enercoop: unquote('"\\e944"');
$ph-logement-semaine-partie-de-journee-enercoop: unquote('"\\e945"');
$ph-logement-semaine-toute-la-journee-enercoop: unquote('"\\e946"');
$ph-logement-table-fonte-enercoop: unquote('"\\e947"');
$ph-logement-table-gaz-enercoop: unquote('"\\e948"');
$ph-logement-table-induction-enercoop: unquote('"\\e949"');
$ph-logement-table-mixte-enercoop: unquote('"\\e94a"');
$ph-logement-table-vitroceramique-enercoop: unquote('"\\e94b"');
$ph-logement-tv-enercoop: unquote('"\\e94c"');
$ph-logement-ventilateur-enercoop: unquote('"\\e94d"');
$ph-logement-weekend-partie-journee-enercoop: unquote('"\\e94e"');
$ph-logement-weekend-toute-la-journee-enercoop: unquote('"\\e94f"');
$ph-mail-enercoop: unquote('"\\e950"');
$ph-ok-enercoop: unquote('"\\e951"');
$ph-rogervoice-enercoop: unquote('"\\e952"');
$ph-youtube-enercoop: unquote('"\\e953"');
$ph-alarm: unquote('"\\e006"');
$ph-arrow-left-bold: unquote('"\\e058"');
$ph-arrow-right-bold: unquote('"\\e06c"');
$ph-arrow-square-out: unquote('"\\e5de"');
$ph-bank-bold: unquote('"\\e0b4"');
$ph-bank: unquote('"\\e0b5"');
$ph-box-arrow-down: unquote('"\\e00e"');
$ph-calendar: unquote('"\\e108"');
$ph-caret-down-bold: unquote('"\\e136"');
$ph-caret-left-bold: unquote('"\\e138"');
$ph-caret-right-bold: unquote('"\\e13a"');
$ph-chart-bar: unquote('"\\e150"');
$ph-chats: unquote('"\\e17c"');
$ph-check-bold: unquote('"\\e182"');
$ph-check: unquote('"\\e183"');
$ph-circle-notch: unquote('"\\eb44"');
$ph-clock: unquote('"\\e19a"');
$ph-credit-card-bold: unquote('"\\e1d2"');
$ph-credit-card: unquote('"\\e1d3"');
$ph-currency-eur: unquote('"\\e554"');
$ph-desktop-tower: unquote('"\\e562"');
$ph-download-simple-bold: unquote('"\\e20c"');
$ph-drop-slash: unquote('"\\e954"');
$ph-eye-bold: unquote('"\\e220"');
$ph-file: unquote('"\\e230"');
$ph-file-pdf: unquote('"\\e702"');
$ph-files: unquote('"\\e710"');
$ph-game-controller: unquote('"\\e26e"');
$ph-gauge: unquote('"\\e628"');
$ph-gear: unquote('"\\e270"');
$ph-gift: unquote('"\\e276"');
$ph-hair-dryer: unquote('"\\ea66"');
$ph-house: unquote('"\\e2c2"');
$ph-identification-badge: unquote('"\\e6f6"');
$ph-info: unquote('"\\e2ce"');
$ph-laptop: unquote('"\\e586"');
$ph-lightning: unquote('"\\e2de"');
$ph-list-bold: unquote('"\\e2f0"');
$ph-lock-bold: unquote('"\\e2fa"');
$ph-magnifying-glass: unquote('"\\e30c"');
$ph-map-pin: unquote('"\\e316"');
$ph-megaphone: unquote('"\\e324"');
$ph-minus-circle: unquote('"\\e32c"');
$ph-newspaper: unquote('"\\e344"');
$ph-note-pencil-bold: unquote('"\\e34c"');
$ph-paper-plane-tilt: unquote('"\\e398"');
$ph-phone: unquote('"\\e3b8"');
$ph-plug-charging: unquote('"\\eb5c"');
$ph-plus-circle: unquote('"\\e3d6"');
$ph-printer: unquote('"\\e3dc"');
$ph-question: unquote('"\\e3e8"');
$ph-receipt: unquote('"\\e3ec"');
$ph-rocket-launch: unquote('"\\e3fe"');
$ph-scales: unquote('"\\e750"');
$ph-sort-ascending-bold: unquote('"\\e444"');
$ph-sort-descending-bold: unquote('"\\e446"');
$ph-student: unquote('"\\e73e"');
$ph-sun: unquote('"\\e472"');
$ph-table: unquote('"\\e476"');
$ph-thermometer-simple: unquote('"\\e5cc"');
$ph-towel: unquote('"\\ede6"');
$ph-trash-bold: unquote('"\\e4a6"');
$ph-user-circle-bold: unquote('"\\e4c4"');
$ph-warning: unquote('"\\e4e0"');
$ph-whatsapp-logo: unquote('"\\e5d0"');
$ph-wifi-high: unquote('"\\e4ea"');
$ph-wrench: unquote('"\\e5d4"');
$ph-x-bold: unquote('"\\e4f6"');

