@mixin pill
  padding: 2px 10px
  font-weight: 500
  border-radius: 1em
  display: inline-block

.pill-danger,
.pill-rouge
  @include pill
  @include danger

.pill-info
  @include pill
  @include info

.pill-success,
.pill-vert
  @include pill
  @include success

.pill-warning,
.pill-jaune
  @include pill
  @include warning

.pill-turquoise
  @include pill
  color: $turquoise-dark
  background: $turquoise-xlight

.pill-bleu
  @include pill
  color: $bleu-dark
  background: $bleu-light

.pill-bleu-fonce
  @include pill
  color: $bleu-fonce-dark
  background: $bleu-fonce-light
