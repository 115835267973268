.pre_header
  background-color: $primary-color
  color: $blanc
  padding: spacer(1) spacer(2)
  display: flex
  align-items: center
  justify-content: flex-end

  .pre_header_item
    border-right: 1px solid $blanc
    margin-left: spacer(2)
    padding-right: spacer(2)
    display: flex
    align-items: center

    &:last-of-type
      border: none
      padding-right: 0

    &:first-of-type
      margin-left: 0

    span
      @extend .truncate

  a
    @extend .ui-s-med
    color: $blanc
    transition: opacity $transition-duration
    display: flex
    align-items: center
    &:hover, &.active
      opacity: 0.7

  i
    margin-right: spacer(1)

@media #{$breakpoint-smaller-than-md-992px}
  .pre_header.desktop
    .pre_header_item
      display: none

@media #{$breakpoint-larger-than-md-992px}
  .pre_header_item
    a
      margin-top: 0

.pre_header.mobile
  display: block
  padding: 0 spacer(2)
  .pre_header_item
    display: block
    margin-left: 0
    padding: spacer(3) 0
    border-right: none
    border-bottom: solid 1px rgba( $blanc, .3 )
    i
      margin-left: 0
