@mixin alert
  padding: spacer(2)
  margin: spacer(2) 0
  text-align: left
  line-height: 1.5em
  color: $noir

  &.text-right
    text-align: right

    [class^="ph-"]
      flex-direction: row-reverse
      &:before
        margin-left: spacer(2)
        margin-right: 0

  [class^="ph-"]
    font-size: 1em
    height: 100%
    display: flex
    align-items: center

    p
      margin-bottom: 0

    &:before
      font-size: 1.5em
      margin-right: spacer(2)
      display: flex
      font-family: $icon-font

  a
    display: inline

  code
    background-color: inherit

.alert-danger
  @include alert
  background: $rouge-light
  [class^="ph-"]:before
    color: $rouge-dark
  a
    color: $rouge-dark

.alert-warning
  @include alert
  @include warning
  color: $noir
  [class^="ph-"]:before
    color: $jaune-dark
  a
    color: $jaune-dark

.alert-info
  @include alert
  background: $bleu-light
  [class^="ph-"]:before
    color: $bleu-dark
  a
    color: $bleu-dark

.alert-success
  @include alert
  background: $turquoise-xlight
  [class^="ph-"]:before
    color: $turquoise
  a
    color: $turquoise

.alert-transparent
  @include alert
