.result-message
  max-width: 280px
  display: flex
  flex-direction: column
  align-items: center
  margin: 1em auto
  text-align: center

  p
    @extend .mt-2
    @extend .ui-l-semi
