@mixin stack
  padding: 2px 10px
  font-weight: 500
  border-radius: 1em
  display: list-item

.stack-danger,
.stack-rouge
  @include stack
  @include danger

.stack-info
  @include stack
  @include info

.stack-success,
.stack-vert
  @include stack
  @include success

.stack-warning,
.stack-jaune
  @include stack
  @include warning

.stack-turquoise
  @include stack
  color: $turquoise-dark
  background: $turquoise-xlight

.stack-bleu
  @include stack
  color: $bleu-dark
  background: $bleu-light

.stack-bleu-fonce
  @include stack
  color: $bleu-fonce-dark
  background: $bleu-fonce-light
