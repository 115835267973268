.truncate
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.no-wrap
  white-space: nowrap

.align-center
  text-align: center

.align-right
  text-align: right

.turquoise
  color: $turquoise
.bleu
  color: $bleu
.rouge
  color: $rouge
.vert
  color: $vert
.bleu-fonce
  color: $bleu-fonce
.jaune
  color: $jaune-dark
.gris-fonce
  color: $gris-fonce

.icon-and-label
  display: flex
  align-items: center
  i
    margin-right: spacer(1)
