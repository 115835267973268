.form-card
  background-color: $blanc
  border-radius: var(--rounded-lg)
  margin-bottom: var(--margin-6)
  border: var(--border) solid $gris
  margin-top: var(--large-margin)

.form-card__title
  border-top-left-radius: var(--rounded-lg)
  border-top-right-radius: var(--rounded-lg)
  padding: var(--margin-4) var(--margin-6)
  border-bottom: var(--border) solid $gris
  background-color: lighten($gris, 10%)
  > .form-title
    margin: 0


.form-card__content
  padding: var(--margin-6)
  > label:first-child
    margin-top: 0 // We already have a padding, no need to margin for first label


/* used to create a link to add a card
 * no need to create component
 */
.add-form-card
  padding: var(--margin-4)
  margin: var(--margin-6) 0
  display: flex
  align-items: center
  justify-content: center
  gap: var(--margin-2)
  width: 100%

  border: var(--border-2) dashed #e5e7eb
  border-radius: var(--rounded-lg)

  color: $vert
  font-weight: 700

  background-color: transparent
  transition: all 0.2s ease-in-out
  cursor: pointer

.add-form-card:hover
  background-color: $vert-xlight
  border-color: $vert

.add-form-card:active
  transform: scale(0.98)

