.p-2
  padding: var(--padding-2)
.p-4
  padding: var(--padding-4)
.p-8
  padding: var(--padding-8)

.mb-4
  margin-bottom: var(--margin-4)
.mb-8
  margin-bottom: var(--margin-8)
