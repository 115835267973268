@use "sass:map"

.vc-spinner
  display: flex
  flex-flow: row nowrap
  align-items: center
  margin-bottom: 1rem
  button
    border: none
    padding: 0
    background: inherit
    cursor: pointer
    &:hover
      background: inherit
    svg
      width: 2em
      height: 2em
      vertical-align: middle
      path
        fill: $bleu
      &:hover
        path
          fill: $bleu-dark
  &.disabled
    button
      cursor: not-allowed
    svg>path
      fill: $bleu-xlight
    &:hover
      path
        fill: $bleu-xlight
  &.readonly
    button
      cursor: not-allowed
    svg>path
      fill: $bleu-light
    &:hover
      path
        fill: $bleu-light
  .vc-number-with-unit
    margin: 0 0.5em
    width: 100%

// Themes
@mixin vc-spinner-theme($color)
  .vc-spinner[data-theme="#{$color}"]
    button
      svg>path
        fill: map.get($theme-colors, "#{$color}")
        &:hover path
          fill: map.get($theme-colors, "#{$color}-dark")
    &.disabled
      button
        svg>path
          fill: map.get($theme-colors, "#{$color}-xlight")
        &:hover path
          fill: map.get($theme-colors, "#{$color}-xlight")
    &.readonly
      button
        svg>path
          fill: map.get($theme-colors, "#{$color}-light")
        &:hover path
          fill: map.get($theme-colors, "#{$color}-light")

@include vc-spinner-theme("beige")
@include vc-spinner-theme("blanc")
@include vc-spinner-theme("bleu")
@include vc-spinner-theme("brun")
@include vc-spinner-theme("jaune")
@include vc-spinner-theme("rouge")
@include vc-spinner-theme("turquoise")
@include vc-spinner-theme("vert")
