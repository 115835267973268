@mixin title-text
  font-family: $title-font
  text-transform: uppercase
  font-weight: normal
  line-height: 110%

body
  font-family: $base-font
  font-size: $base-size
  color: $noir
  background-color: $light-color

main
  background-color: $light-color

h1, h2, h3, h4, h5, h6
  @include title-text
  margin: 0 0 spacer(4) 0

p, ul
  margin: 0 0 spacer(2) 0
  line-height: 150%
  &.unstyled
    list-style: none

dl
  dt
    font-weight: bold

a
  text-decoration: none
  color: $noir

.text-xs, i.text-xs
  font-size: var(--font-size-xs)
  line-height: var(--line-height-xs)
.text-sm, i.text-sm
  font-size: var(--font-size-sm)
  line-height: var(--line-height-sm)
.text-base, i.text-base
  font-size: var(--font-size-base)
  line-height: var(--line-height-base)
.text-lg, i.text-lg
  font-size: var(--font-size-lg)
  line-height: var(--line-height-lg)
.text-xl, i.text-xl
  font-size: var(--font-size-xl)
  line-height: var(--line-height-xl)
.text-2xl, i.text-2xl
  font-size: var(--font-size-2xl)
  line-height: var(--line-height-2xl)
.text-3xl, i.text-3xl
  font-size: var(--font-size-3xl)
  line-height: var(--line-height-3xl)
.text-4xl, i.text-4xl
  font-size: var(--font-size-4xl)
  line-height: var(--line-height-4xl)
.text-5xl, i.text-5xl, i:is(.text-5xl)
  font-size: var(--font-size-5xl)
  line-height: var(--line-height-5xl)
.text-6xl, i.text-6xl
  font-size: var(--font-size-6xl)
  line-height: var(--line-height-6xl)
.text-7xl, i.text-7xl
  font-size: var(--font-size-7xl)
  line-height: var(--line-height-7xl)
.text-8xl, i.text-8xl
  font-size: var(--font-size-8xl)
  line-height: var(--line-height-8xl)
.text-9xl, i.text-9xl
  font-size: var(--font-size-9xl)
  line-height: var(--line-height-9xl)

/* add external link indicator, excluding buttons, special links and manual exceptions */
[target="_blank"]:not([href^=mailto], [href^=tel], [class*='btn'], .link-no-ext-icon):after
  font-family: 'Phosphor' !important
  content: $ph-arrow-square-out

/* remove external link indicator from navigation links */
.pre_header_item > [target="_blank"]:not([href^=mailto], [href^=tel], [class*='btn']):after,
.box-footer > [target="_blank"]:not([href^=mailto], [href^=tel], [class*='btn']):after,
.welcome-nav [target="_blank"]:not([href^=mailto], [href^=tel], [class*='btn']):after,
.docs-nav [target="_blank"]:not([href^=mailto], [href^=tel], [class*='btn']):after,
.footer-nav [target="_blank"]:not([href^=mailto], [href^=tel], [class*='btn']):after,
.social-network [target="_blank"]:not([href^=mailto], [href^=tel], [class*='btn']):after,
.widgets [target="_blank"]:not([href^=mailto], [href^=tel], [class*='btn']):after,
.parrainage-links [target="_blank"]:not([href^=mailto], [href^=tel], [class*='btn']):after,
.share [target="_blank"]:not([href^=mailto], [href^=tel], [class*='btn']):after,
.grid-se .box[target="_blank"]:not([href^=mailto], [href^=tel], [class*='btn']):after
  display: none

small
  font-size: 12px
  font-weight: normal

h1, .h1-size
  font-size: 3rem
  line-height: 1
h2, .h2-size
  font-size: 2.25rem
  line-height: 2.5rem
h3, .h3-size
  font-size: 1.875rem
  line-height: 2.25rem
h4, .h4-size
  font-size: 1.5rem
  line-height: 2rem
  margin-bottom: spacer(2)
h5, .h5-size
  font-size: 1.25rem
  line-height: 1.75rem
  margin-bottom: spacer(2)
h6, .h6-size
  font-size: 1rem
  line-height: 1.5rem
  margin-bottom: spacer(2)
.big-number-size
  font-size: 8rem
  line-height: 1

@mixin h1-size-larger-than-sm-768px
  font-size: calc(3.125rem + ((1vw - 7.68px) * 4.8077)) //50px to 90px
  line-height: 1
@mixin h2-size-larger-than-sm-768px
  font-size: calc(2.5rem + ((1vw - 7.68px) * 2.4038)) //40px to 60px
  line-height: 1
@mixin h3-size-larger-than-sm-768px
  font-size: calc(1.875rem + ((1vw - 7.68px) * 1.2019)) //30px to 40px
  line-height: calc(2.25rem + ((1vw - 7.68px) * 0.5208))
@mixin h4-size-larger-than-sm-768px
  font-size: calc(1.5rem + ((1vw - 7.68px) * 0.7212)) //24px to 30px
  line-height: calc(2rem + ((1vw - 7.68px) * 0.5208))
@mixin h5-size-larger-than-sm-768px
  font-size: calc(1.125rem + ((1vw - 7.68px) * 0.5208)) //18px to 24px
  line-height: calc(1.75rem + ((1vw - 7.68px) * 0.5208))
@mixin big-number-size-larger-than-sm-768px
  font-size: calc(7.5rem + ((1vw - 7.68px) * 8.4135)) //120px to 190px
  line-height: 1

// Fluid typo calculate with : https://websemantics.uk/tools/responsive-font-calculator/
@media #{$breakpoint-larger-than-sm-768px}
  h1, .h1-size
    @include h1-size-larger-than-sm-768px
  h2, .h2-size
    @include h2-size-larger-than-sm-768px
  h3, .h3-size
    @include h3-size-larger-than-sm-768px
  h4, .h4-size
    @include h4-size-larger-than-sm-768px
  h5, .h5-size
    @include h5-size-larger-than-sm-768px
  .big-number-size
    @include big-number-size-larger-than-sm-768px

@mixin h1-size-larger-than-xl-1600px
  font-size: 6rem
  line-height: 1
@mixin h2-size-larger-than-xl-1600px
  font-size: 3.75rem
  line-height: 1
@mixin h3-size-larger-than-xl-1600px
  font-size: 2.5rem
  line-height: 1
@mixin h4-size-larger-than-xl-1600px
  font-size: 1.875rem
  line-height: 2.25rem
@mixin h5-size-larger-than-xl-1600px
  font-size: 1.5rem
  line-height: 2rem
@mixin big-number-size-larger-than-xl-1600px
  font-size: 11.875rem
  line-height: 1

@media #{$breakpoint-larger-than-xl-1600px}
  h1, .h1-size
    @include h1-size-larger-than-xl-1600px
  h2, .h2-size
    @include h2-size-larger-than-xl-1600px
  h3, .h3-size
    @include h3-size-larger-than-xl-1600px
  h4, .h4-size
    @include h4-size-larger-than-xl-1600px
  h5, .h5-size
    @include h5-size-larger-than-xl-1600px
  .big-number-size
    @include big-number-size-larger-than-xl-1600px

.ui-l-semi, .ui-m-med, .ui-m-reg, .ui-s-semi, .ui-s-med, .ui-s-reg
  font-family: $base-font
  text-transform: none

.ui-l-semi
  font-size: 1.25rem
  line-height: 1.75rem
  font-weight: 600

.ui-m-med
  font-size: 1rem
  line-height: 1.5rem
  font-weight: 500

.ui-m-reg
  font-size: 1rem
  line-height: 1.5rem
  font-weight: normal

.ui-s-semi
  font-size: 0.875rem
  line-height: 1.25rem
  font-weight: 600

.ui-s-med
  font-size: 0.875rem
  line-height: 1.25rem
  font-weight: 500

.ui-s-reg
  font-size: 0.875rem
  line-height: 1.25rem
  font-weight: normal

.ui-label-reg
  font-family: $base-font
  font-size: 0.75rem
  line-height: 1rem
  text-transform: uppercase
  color: $gris-fonce

.ui-button-m
  @include title-text
  font-size: 0.875rem
  line-height: 1.25rem
  letter-spacing: 0.025em

.font-normal
  font-weight: 400
.font-medium
  font-weight: 500
.font-semibold
  font-weight: 600

.uppercase
  text-transform: uppercase

.tracking-wide
  letter-spacing: 0.025em

hr
  border: 0
  margin: 0
  border-top: 1px solid $gris

.emphasized-text
  span
    display: block
    color: $noir
  em
    font-style: normal
    color: $primary-color // if block has no scheme color
    @include themify($schemes)
      color: schemed('secondary')

@mixin bullets($color)
  li::marker
    color: $color

.bullets-bleu
  @include bullets($bleu)
