// DEPRECATED: Please use FooterComponent.
#footer
  background-color: $primary-color
  color: $blanc
  padding: spacer(4) 0

  h3
    @extend .ui-m-med
    color: $blanc
    font-weight: 800

@media screen and (min-width: 992px)
  #footer
    .container-fluid > div:last-child
      display: flex
