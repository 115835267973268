.container-login
  padding-right: 1rem
  padding-left: 1rem

.login-hero
  display: flex
  flex-direction: column-reverse
  align-items: stretch

  background-repeat: no-repeat
  background-size: cover
  min-height: 100vh

.login-testimonial
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0.44%, rgba(48, 43, 43, 0.927083) 28.66%, rgba(52, 51, 51, 0.957176) 50.15%)
  margin: 0 $row-margin
  padding: 48px 178px spacer(4) 52px
  color: $blanc

  .login-testimonial-quote
    @extend .blockquote
    @extend .emphasized-text

    font-family: $title-font
    font-size: 20px
    line-height: 38px
    text-transform: uppercase

    span
      color: $blanc
    em
      color: $vert

  img.logo
    max-width: 100%
    height: auto


.login-testimonial-author
  font-size: 18px
  margin-bottom: spacer(2)

.login
  background: $blanc
  padding-top: 48px
  display: flex
  flex-direction: column
  justify-content: space-between

.login-main
  display: flex
  flex-direction: column
  margin: 0 $row-margin
  height: 100%

.login-logo
  align-self: center

.login-form
  padding-top: 48px

  padding-left: spacer(2)
  padding-right: spacer(2)
  @media #{$breakpoint-larger-than-xl-1600px}
    padding-left: 96px
    padding-right: 96px

.login_title
  font-family: $title-font
  font-size: 20px
  font-weight: 400
  text-transform: uppercase

.login-aside
  margin-top: auto
  padding: spacer(4) 0
  padding-left: spacer(2)
  @media #{$breakpoint-larger-than-xl-1600px}
    padding-left: 96px
  p
    font-size: 14px
  h2
    font-family: $title-font
    font-size: 24px
    text-transform: uppercase

  background: $light-color
