.item_info
  display: flex
  align-items: center
  padding: spacer(2)
  border-radius: 4px
  white-space: inherit
  color: $bleu-fonce
  font-weight: bold
  background-color: $bleu-fonce-xlight
  margin-top: spacer(1)

  .item_info__label
    display: flex
    align-items: center

  i[class^="ph-"], i[class*=" ph-"]
    margin-right: spacer(1)
    font-weight: bold

    & + span
      display: inherit
      background-color: inherit

  // fix a display bug for bank icons
  i.ph-bank, i.ph-bank-bold
    font-weight: inherit

.item_info--with_unit
  justify-content: space-between

.item_info--with-consumption-per-cadran
  display: block
  margin-top: -20px

.item_info__consumption-per-cadran
  font-weight: normal
  display: flex
  align-items: center
  justify-content: space-between
  padding-left: 32px
  margin-bottom: 2px

.item_info__unit
  text-align: end
