@use "sass:map"

.vc-number-with-unit
  display: flex
  flex-flow: row nowrap
  align-items: center
  margin-bottom: 1rem
  padding: 0 0.5em
  background-color: $blanc
  border-color: $gris
  border-width: 1px
  border-style: solid
  border-radius: 4px
  &.disabled
    color: $gris-neutre
  &.readonly
    background-color: $bleu-xlight
    color: $gris-neutre
    &:focus-within
      outline: 1px solid $bleu-light
  &.error
    outline: 1px solid $rouge
  &:focus-within
    outline: 1px solid $bleu-dark
  input[type="text"]
    padding: 0.5rem 1rem 0.5rem 0.5rem
    margin: 0
    border: none
    text-align: right
    &:focus
      outline: none
    &.input-small
      padding: 3px 11px
  span
    white-space: nowrap

  // modifiers
  &.vc-number-with-unit--centered
    display: grid
    grid-template-columns: repeat(2, 1fr)
    input[type="text"]
      padding-right: spacer(1)


// Themes
@mixin vc-number-with-unit-theme($color)
  .vc-number-with-unit[data-theme="#{$color}"]
    input[type="text"], span
      font-weight: bold
      color: map.get($theme-colors, $color)

@include vc-number-with-unit-theme("vert")
