.w-full
  width: 100%

.max-w-0
  max-width: 0
.max-w-px
  max-width: 1px
.max-w-1
  max-width: 0.25rem /* 4px */
.max-w-2
  max-width: 0.5rem /* 8px */
.max-w-3
  max-width: 0.75rem /* 12px */
.max-w-4
  max-width: 1rem /* 16px */
.max-w-5
  max-width: 1.25rem /* 20px */
.max-w-6
  max-width: 1.5rem /* 24px */
.max-w-7
  max-width: 1.75rem /* 28px */
.max-w-8
  max-width: 2rem /* 32px */
.max-w-9
  max-width: 2.25rem /* 36px */
.max-w-10
  max-width: 2.5rem /* 40px */
.max-w-11
  max-width: 2.75rem /* 44px */
.max-w-12
  max-width: 3rem /* 48px */
.max-w-14
  max-width: 3.5rem /* 56px */
.max-w-16
  max-width: 4rem /* 64px */
.max-w-20
  max-width: 5rem /* 80px */
.max-w-24
  max-width: 6rem /* 96px */
.max-w-28
  max-width: 7rem /* 112px */
.max-w-32
  max-width: 8rem /* 128px */
.max-w-36
  max-width: 9rem /* 144px */
.max-w-40
  max-width: 10rem /* 160px */
.max-w-44
  max-width: 11rem /* 176px */
.max-w-48
  max-width: 12rem /* 192px */
.max-w-52
  max-width: 13rem /* 208px */
.max-w-56
  max-width: 14rem /* 224px */
.max-w-60
  max-width: 15rem /* 240px */
.max-w-64
  max-width: 16rem /* 256px */
.max-w-72
  max-width: 18rem /* 288px */
.max-w-80
  max-width: 20rem /* 320px */
.max-w-96
  max-width: 24rem /* 384px */
.max-w-none
  max-width: none
.max-w-xs
  max-width: 20rem /* 320px */
.max-w-sm
  max-width: 24rem /* 384px */
.max-w-md
  max-width: 28rem /* 448px */
.max-w-lg
  max-width: 32rem /* 512px */
.max-w-xl
  max-width: 36rem /* 576px */
.max-w-2xl
  max-width: 42rem /* 672px */
.max-w-3xl
  max-width: 48rem /* 768px */
.max-w-4xl
  max-width: 56rem /* 896px */
.max-w-5xl
  max-width: 64rem /* 1024px */
.max-w-6xl
  max-width: 72rem /* 1152px */
.max-w-7xl
  max-width: 80rem /* 1280px */
.max-w-full
  max-width: 100%
.max-w-min
  max-width: min-content
.max-w-max
  max-width: max-content
.max-w-fit
  max-width: fit-content
.max-w-prose
  max-width: 65ch
.max-w-screen-sm
  max-width: 640px
.max-w-screen-md
  max-width: 768px
.max-w-screen-lg
  max-width: 1024px
.max-w-screen-xl
  max-width: 1280px
.max-w-screen-2xl
  max-width: 1536px
