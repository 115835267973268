.large-tabs
  display: flex
  gap: spacer(4)
  border-bottom: 1px solid $gris
  margin-bottom: spacer(2)

  .large-tab
    @include title-text
    @extend .h5-size
    padding-bottom: spacer(2)
    border-bottom: 2px solid transparent // This makes sure that the height is always the same, even if there is no active tab
    margin-bottom: -1px
    opacity: 0.5
    transition: opacity $transition-duration
    color: $bleu

    &.active, &:hover
      color: $bleu-dark
      opacity: 1

    &.active
      border-bottom: 2px solid $bleu-dark

.small-tabs
  display: flex
  gap: spacer(3)

  .small-tab, a.small-tab
    @extend .ui-s-med
    color: $gris-fonce
    transition: color $transition-duration

    &.active, &:hover
      color: $bleu-dark

    &.active
      text-decoration: underline
      text-underline-offset: spacer(1)
      text-decoration-thickness: 2px
