nav.pagination
  display: flex
  justify-content: flex-end
  align-items: flex-end
  padding-top: spacer(6)
  padding-bottom: spacer(4)
  text-align: center
  span, .dropdown-trigger
    display: flex
    margin-right: spacer(1)
    .select
      margin-bottom: 0

    &:last-child
      margin-right: 0

  span a, span.current
    @extend .btn-secondary
