.modal-component
  &:modal
    background-color: $blanc
    border-radius: $border-radius
    width: 80vw
    max-width: 650px
    padding: 0
    border: none
    overflow: hidden

  &::backdrop
    background-color: rgba($noir, 0.5)

  header
    padding: 20px 12px
    color: $blanc
    display: flex
    align-items: center
    height: auto
    background-color: $bleu
    .title
      display: flex
      align-items: center
      font-size: $base-size
      font-family: $title-font
      margin-right: auto
      i
        margin-right: spacer(1)
    button
      background-color: transparent
      border: none
      padding: 0
      color: $blanc
      cursor: pointer
      line-height: 0
      i
        pointer-events: none

  .content
    padding: spacer(2)
    overflow-y: scroll
    box-sizing: border-box
    max-height: calc(80vh - 2 * 20px - 1em * 1.2)  /* 80% - header height */
    .colored-section--blue
      margin-left: -1 * spacer(2)
      margin-right: -1 * spacer(2)
      margin-top: spacer(2)
      padding: spacer(2)
      background-color: $bleu-light
      color: $bleu-dark
      &:last-child
        margin-bottom: -1 * spacer(2)
      .title
        display: flex
        align-items: center
        margin-bottom: spacer(1)
        i
          margin-right: spacer(1)
    p:last-child
      margin-bottom: 0

    // Seems like buttons in modal are blue, whatever the $primary-color is:
    // see `header` background-color above.
    // That's why we need to override button colors definition here.
    .btn-primary
      background-color: $bleu
      color: $blanc
      border: 1px solid $bleu
      &:hover
        background-color: $bleu-dark
        border: 1px solid $bleu-dark

  @media #{$breakpoint-smaller-than-sm-768px}
    &:modal
      max-height: 100%
      max-width: 100%
      width: 100%
      height: 100%
    .content
      max-height: calc(100vh - 2 * 20px - 1em * 1.2)  /* 100% - header height */

.modal-component--with-links .content
  padding-bottom: 0

body.noscroll
  overflow: hidden

[data-trigger-modal]
  cursor: pointer
