ul.download-list
  padding-left: 0
  list-style: none

  li
    display: flex
    margin-top: spacer(2)
    &:before
      font-family: $icon-font
      content: $ph-download-simple-bold
      display: inline-block
      margin-right: spacer(1)
      font-size: 20px
    > a
      text-decoration: underline
      &[target="_blank"]:not([href^="mailto"], [href^="tel"], [class*="btn"], .link-no-ext-icon)::after
        // do not display "\f291" - arrow-square-out icon
        display: none

