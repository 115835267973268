@use "sass:map"

#stepper
  display: flex
  justify-content: center
  align-items: center
  margin-bottom: 1.5rem

  .step
    @extend .ui-s-semi
    > .circle
      width: 1.5rem
      height: 1.5rem
      line-height: 1.5rem
      text-align: center
      border-radius: 50%
      display: inline-block
    > span.title
      display: none
  .step-wrapper
    display: flex
    &.step--done
      .step
        > span.circle
          background-color: $gris-nuit
          mix-blend-mode: luminosity
          color: $blanc
        > span.title
          color: $gris-nuit
      &::before
        background-color: $gris-nuit
        mix-blend-mode: luminosity
    &.step--current
      position: relative
      &:after
        content: ""
        width: 0.5rem
        height: 0.5rem
        border-radius: 0.5rem
        position: absolute
        bottom: -14px
        right: 0.5rem
        align-self: center
      .step
        > span.circle
          background-color: $gris-nuit
          color: $blanc
        > span.title
          color: $gris-nuit
      &::before
        background-color: $gris-nuit
        mix-blend-mode: luminosity
      &::after
        background-color: $gris-nuit
    &.step--todo
      .step
        > span.circle
          color: $gris-nuit
          background-color: rgba($noir, 0.15)
        > span.title
          color: $gris-nuit
      &::before
        background-color: rgba($noir, 0.15)
    &.step--check
      .step
        > span.circle
          background-color: $gris-nuit
          color: $blanc
          font-size: 0
          &::before
            content: $ph-check-bold
            font-family: $icon-font
            font-size: 1rem
        > span.title
          color: $gris-nuit
      &::before
        background-color: $gris-nuit

  .step-wrapper + .step-wrapper::before
    content: ""
    display: block
    min-width: 1.5rem
    height: 2px
    align-self: center

@media #{$breakpoint-larger-than-sm-768px}
  #stepper
    display: block
    .step
      > span.title
        display: inline-block
        margin-left: 1.7rem
    .step-wrapper.step--todo .step > span.title
      font-weight: 400
    .step-wrapper + .step-wrapper
      display: block
      &::before
        height: 30px
        min-width: inherit
        width: 2px
        margin-left: calc((1.5rem - 2px)/2)
    .step-wrapper
      &.step--current
        &::after
          bottom: 0.5rem
          height: 0.5rem
          left: 2rem
          right: auto

    &.lg-invert
      .step-wrapper
        &.step--done
          .step
            > span.circle
              color: $blanc
            > span.title
              color: $blanc
        &.step--current
          .step
            > span.circle
              background-color: $blanc
              color: $gris-nuit
            > span.title
              color: $blanc
          &::after
            background-color: $blanc
        &.step--todo
          .step
            > span.circle
              color: $blanc
            > span.title
              color: $blanc
        &.step--check
          .step
            > span.circle
              background-color: $blanc
              color: $gris-nuit
            > span.title
              color: $blanc

@media #{$breakpoint-smaller-than-sm-768px}
  #stepper
    position: inherit

    &.steps-3
      .step-wrapper + .step-wrapper::before
        min-width: calc((100vw - 3 * 1.5rem) / 3 - 1rem)
    &.steps-6
      .step-wrapper + .step-wrapper::before
        min-width: calc((100vw - 6 * 1.5rem) / 6 - 1rem)

    &.xs-invert
      .step-wrapper
        &.step--done
          .step
            > span.circle
              color: $blanc
            > span.title
              color: $blanc
        &.step--current
          .step
            > span.circle
              background-color: $blanc
              color: $gris-nuit
            > span.title
              color: $blanc
          &::after
            background-color: $blanc
        &.step--todo
          .step
            > span.circle
              color: $blanc
            > span.title
              color: $blanc
        &.step--check
          .step
            > span.circle
              background-color: $blanc
              color: $gris-nuit
            > span.title
              color: $blanc

// Themes
@mixin vc-stepper-theme($color)
  #stepper[data-theme="#{$color}"]
    .step-wrapper
      &.step--current
        .step
          > span.circle
            color: $blanc
            background-color: map.get($theme-colors, $color)
          > span.title
            color: map.get($theme-colors, $color)
        &::after
          background-color: map.get($theme-colors, $color)
          mix-blend-mode: inherit

  @media #{$breakpoint-larger-than-sm-768px}
    #stepper.lg-invert
      .step-wrapper
        &.step--current
          .step
            > span.circle
              color: map.get($theme-colors, $color)
              background-color: $blanc
            > span.title
              color: $blanc
          &::after
            background-color: $blanc

  @media #{$breakpoint-smaller-than-sm-768px}
    #stepper.xs-invert
      .step-wrapper
        &.step--current
          .step
            > span.circle
              color: map.get($theme-colors, $color)
              background-color: $blanc
            > span.title
              color: $blanc
          &::after
            background-color: $blanc

@include vc-stepper-theme("beige")
@include vc-stepper-theme("blanc")
@include vc-stepper-theme("bleu")
@include vc-stepper-theme("brun")
@include vc-stepper-theme("jaune")
@include vc-stepper-theme("rouge")
@include vc-stepper-theme("turquoise")
@include vc-stepper-theme("vert")
