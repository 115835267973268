// This code is taken from the micromodal demo found here: https://gist.github.com/ghosh/4f94cf497d7090359a5c9f81caf60699

.modal
  display: none
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba(0, 0, 0, 0.6)
  overflow-y: auto
  align-items: baseline
  justify-content: center
  z-index: 5

  &.is-open
    display: flex

  &.small
    align-items: center

  &[aria-hidden="false"]
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1)

    .modal-container
      animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1)

.modal-container, .bloc.modal-container
  position: relative
  background-color: #fff
  padding: 8vh spacer(2)
  min-height: 84vh
  width: 100%
  overflow-y: auto

.small .modal-container
  padding: spacer(3)
  min-height: 0
  width: 70%
  border-radius: 4px

.close-modal
  position: absolute
  top: spacer(2)
  right: spacer(2)

.modal-content
  .btn
    align-self: center
    margin-top: 1rem

@keyframes mmfadeIn
  from
    opacity: 0
  to
    opacity: 1

@keyframes mmslideIn
  from
    transform: translateY(15%)
  to
    transform: translateY(0)


@media #{$breakpoint-larger-than-sm-768px}

  .modal-container, .bloc.modal-container
    padding: spacer(7)
    min-height: 0
    box-sizing: border-box
    width: 70%
    max-height: 90vh

  .small .modal-container
    width: 40%

  .modal
    &.is-open
      align-items: center
