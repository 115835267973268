.legende-pmax
  color: dimgrey
  font-size: 16px
  align-self: center

  & span
    color: $bleu
    font-size: 30px
    vertical-align: -5px
    margin-right: 10px

.square
  height: auto
  width: 0.5rem
