.icon-badge
  padding: 0.25rem
  &[class*="text-"]
    line-height: 1
  &--circle
    padding: spacer(1)
    border-radius: 50%

  &.context-email-validation,
  &.context-discount-code
    margin-left: 0.5rem
