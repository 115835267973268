.input-group-component
  display: flex
  gap: 1rem
  justify-content: space-around
  align-items: stretch
  margin-bottom: spacer(2) // same margin-bottom as input

  .input-group-component__input
    flex-grow: 1
    > *
      margin-bottom: 0 // margin-bottom should be on input-group-component to be able to stretch items to same height

  .input-group-component__button
    > *
      line-height: spacer(2) // force height to match input height
      padding: spacer(2) // idem
