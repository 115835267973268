// SCSS files because we can multiline
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * .5), // 8px
  2: $spacer, // 16px
  3: ($spacer * 1.5), // 24px
  4: ($spacer * 2), // 32px
  5: ($spacer * 2.5), // 40px
  6: ($spacer * 3), // 48px
  7: ($spacer * 4), // 64px
  8: ($spacer * 4.5), // 72px
  9: ($spacer * 5), // 80px
  10: ($spacer * 7), // 112px
  11: ($spacer * 8), // 128px
  12: ($spacer * 9), // 144px
  13: ($spacer * 10),  // 160px
) !default;

@function spacer($size) {
  @return map-get($spacers, $size);
}

$border-radius: 0.25rem /* 4px */
