details
  summary
    cursor: pointer

details.styled-details
  margin-top: var(--large-margin)

  summary::-webkit-details-marker
    display: none

  summary
    color: $vert
    font-weight: bold
    list-style: none
    margin-top: spacer(2)
    margin-bottom: spacer(2)
    display: flex
    align-items: center

    &:hover
      color: $vert-dark

    &::before
      content: $ph-plus-circle
      font-family: $icon-font
      font-size: 1.5em
      margin-right: spacer(1)
      transition: 0.2s ease-in
    > .form_title
      margin-top: spacer(1)

  &[open] > summary::before
    transform: rotate(45deg)

  &[open] summary ~ *
    animation: sweep .5s ease-in-out

@keyframes sweep
  0%
    opacity: 0
  100%
    opacity: 1
