@mixin visible
  display: inherit !important

@mixin hidden
  display: none !important

.visible
  @include visible

.hidden
  @include hidden

[data-default-visibility=hidden]
  @include hidden

  &.active
    @include visible

  &.active.inline-display
    display: inline !important

[data-default-visibility=visible]
  &.inactive
    @include hidden

.hidden-xs
  @media #{$breakpoint-smaller-than-sm-768px}
    @include hidden

.hidden-unless-xs
  @media #{$breakpoint-larger-than-sm-768px}
    @include hidden

.hidden-sm
  @media #{$breakpoint-smaller-than-md-992px}
    @include hidden

.hidden-unless-sm
  @media #{$breakpoint-larger-than-md-992px}
    @include hidden

.hidden-md
  @media #{$breakpoint-smaller-than-lg-1200px}
    @include hidden

.hidden-unless-md
  @media #{$breakpoint-larger-than-lg-1200px}
    @include hidden

.hidden-lg
  @media #{$breakpoint-smaller-than-xl-1600px}
    @include hidden

.hidden-unless-lg
  @media #{$breakpoint-larger-than-xl-1600px}
    @include hidden
