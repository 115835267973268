.amount-component
  display: inline-block

  .amount-component__details,
  .amount-component__amount,
  .amount-component__period,
  .amount-component__taxes
    display: inline-block

.amount-component.amount-component--big
  display: flex
  align-items: center

  .amount-component__amount
    font-size: 2.6rem

  .amount-component__details
    display: flex
    flex-direction: column
