@font-face {
  font-family: 'Phosphor';
  src:
    url('fonts/Phosphor/fonts/Phosphor.ttf?8269ma') format('truetype'),
    url('fonts/Phosphor/fonts/Phosphor.woff?8269ma') format('woff'),
    url('fonts/Phosphor/fonts/Phosphor.svg?8269ma#Phosphor') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Phosphor' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ph-facebook-enercoop:before {
  content: "\e900";
}
.ph-habitation-appartement-enercoop:before {
  content: "\e901";
}
.ph-habitation-autres-enercoop:before {
  content: "\e902";
}
.ph-habitation-cheminee-enercoop:before {
  content: "\e903";
}
.ph-habitation-comble-enercoop:before {
  content: "\e904";
}
.ph-habitation-etages-enercoop:before {
  content: "\e905";
}
.ph-habitation-garage-isole-enercoop:before {
  content: "\e906";
}
.ph-habitation-garage-enercoop:before {
  content: "\e907";
}
.ph-habitation-isolation-bonne-enercoop:before {
  content: "\e908";
}
.ph-habitation-isolation-mauvaise-enercoop:before {
  content: "\e909";
}
.ph-habitation-isolation-moyenne-enercoop:before {
  content: "\e90a";
}
.ph-habitation-locataire-enercoop:before {
  content: "\e90b";
}
.ph-habitation-maison-enercoop:before {
  content: "\e90c";
}
.ph-habitation-mitoyen-enercoop:before {
  content: "\e90d";
}
.ph-habitation-proprietaire-enercoop:before {
  content: "\e90e";
}
.ph-habitation-secondaire-enercoop:before {
  content: "\e90f";
}
.ph-habitation-veranda-enercoop:before {
  content: "\e910";
}
.ph-instagram-enercoop:before {
  content: "\e911";
}
.ph-installation-autre-non-electrique-enercoop:before {
  content: "\e912";
}
.ph-installation-bois-enercoop:before {
  content: "\e913";
}
.ph-installation-chaudiere-gaz-enercoop:before {
  content: "\e914";
}
.ph-installation-chauffe-eau-thermo-enercoop:before {
  content: "\e915";
}
.ph-installation-chauffe-eau-enercoop:before {
  content: "\e916";
}
.ph-installation-collectif-enercoop:before {
  content: "\e917";
}
.ph-installation-electrique-enercoop:before {
  content: "\e918";
}
.ph-installation-fioul-enercoop:before {
  content: "\e919";
}
.ph-installation-gaz-enercoop:before {
  content: "\e91a";
}
.ph-installation-pac-hybride-enercoop:before {
  content: "\e91b";
}
.ph-installation-pac-enercoop:before {
  content: "\e91c";
}
.ph-installation-solaire-conso-totale-enercoop:before {
  content: "\e91d";
}
.ph-installation-solaire-prise-electrique-enercoop:before {
  content: "\e91e";
}
.ph-installation-solaire-revente-surplus-enercoop:before {
  content: "\e91f";
}
.ph-installation-solaire-revente-totale-enercoop:before {
  content: "\e920";
}
.ph-ko-enercoop:before {
  content: "\e921";
}
.ph-link-enercoop:before {
  content: "\e922";
}
.ph-linkedin-enercoop:before {
  content: "\e923";
}
.ph-logement-aquarium-enercoop:before {
  content: "\e924";
}
.ph-logement-aspirateur-enercoop:before {
  content: "\e925";
}
.ph-logement-autre-hifi-enercoop:before {
  content: "\e926";
}
.ph-logement-autre-enercoop:before {
  content: "\e927";
}
.ph-logement-bouilloire-enercoop:before {
  content: "\e928";
}
.ph-logement-brasseur-air-enercoop:before {
  content: "\e929";
}
.ph-logement-cave-a-vin-enercoop:before {
  content: "\e92a";
}
.ph-logement-centrale-vapeur-enercoop:before {
  content: "\e92b";
}
.ph-logement-chambre-enercoop:before {
  content: "\e92c";
}
.ph-logement-climatisation-enercoop:before {
  content: "\e92d";
}
.ph-logement-congelateur-enercoop:before {
  content: "\e92e";
}
.ph-logement-fer-a-repasser-enercoop:before {
  content: "\e92f";
}
.ph-logement-four-encastre-enercoop:before {
  content: "\e930";
}
.ph-logement-four-gaz-enercoop:before {
  content: "\e931";
}
.ph-logement-frigo-americain-enercoop:before {
  content: "\e932";
}
.ph-logement-frigo-congelateur-enercoop:before {
  content: "\e933";
}
.ph-logement-frigo-enercoop:before {
  content: "\e934";
}
.ph-logement-grille-pain-enercoop:before {
  content: "\e935";
}
.ph-logement-halogen-enercoop:before {
  content: "\e936";
}
.ph-logement-lave-linge-enercoop:before {
  content: "\e937";
}
.ph-logement-lave-vaisselle-enercoop:before {
  content: "\e938";
}
.ph-logement-led-enercoop:before {
  content: "\e939";
}
.ph-logement-machine-expresso-enercoop:before {
  content: "\e93a";
}
.ph-logement-marmite-enercoop:before {
  content: "\e93b";
}
.ph-logement-micro-onde-enercoop:before {
  content: "\e93c";
}
.ph-logement-mini-four-enercoop:before {
  content: "\e93d";
}
.ph-logement-partie-de-semaine-partie-de-journee-enercoop:before {
  content: "\e93e";
}
.ph-logement-partie-de-semaine-toute-la-journee-enercoop:before {
  content: "\e93f";
}
.ph-logement-piscine-chauffee-enercoop:before {
  content: "\e940";
}
.ph-logement-recharge-scooter-enercoop:before {
  content: "\e941";
}
.ph-logement-recharge-trotinette-enercoop:before {
  content: "\e942";
}
.ph-logement-robot-enercoop:before {
  content: "\e943";
}
.ph-logement-seche-linge-enercoop:before {
  content: "\e944";
}
.ph-logement-semaine-partie-de-journee-enercoop:before {
  content: "\e945";
}
.ph-logement-semaine-toute-la-journee-enercoop:before {
  content: "\e946";
}
.ph-logement-table-fonte-enercoop:before {
  content: "\e947";
}
.ph-logement-table-gaz-enercoop:before {
  content: "\e948";
}
.ph-logement-table-induction-enercoop:before {
  content: "\e949";
}
.ph-logement-table-mixte-enercoop:before {
  content: "\e94a";
}
.ph-logement-table-vitroceramique-enercoop:before {
  content: "\e94b";
}
.ph-logement-tv-enercoop:before {
  content: "\e94c";
}
.ph-logement-ventilateur-enercoop:before {
  content: "\e94d";
}
.ph-logement-weekend-partie-journee-enercoop:before {
  content: "\e94e";
}
.ph-logement-weekend-toute-la-journee-enercoop:before {
  content: "\e94f";
}
.ph-mail-enercoop:before {
  content: "\e950";
}
.ph-ok-enercoop:before {
  content: "\e951";
}
.ph-rogervoice-enercoop:before {
  content: "\e952";
}
.ph-youtube-enercoop:before {
  content: "\e953";
}
.ph-alarm:before {
  content: "\e006";
}
.ph-arrow-left-bold:before {
  content: "\e058";
}
.ph-arrow-right-bold:before {
  content: "\e06c";
}
.ph-arrow-square-out:before {
  content: "\e5de";
}
.ph-bank-bold:before {
  content: "\e0b4";
}
.ph-bank:before {
  content: "\e0b5";
}
.ph-box-arrow-down:before {
  content: "\e00e";
}
.ph-calendar:before {
  content: "\e108";
}
.ph-caret-down-bold:before {
  content: "\e136";
}
.ph-caret-left-bold:before {
  content: "\e138";
}
.ph-caret-right-bold:before {
  content: "\e13a";
}
.ph-chart-bar:before {
  content: "\e150";
}
.ph-chats:before {
  content: "\e17c";
}
.ph-check-bold:before {
  content: "\e182";
}
.ph-check:before {
  content: "\e183";
}
.ph-circle-notch:before {
  content: "\eb44";
}
.ph-clock:before {
  content: "\e19a";
}
.ph-credit-card-bold:before {
  content: "\e1d2";
}
.ph-credit-card:before {
  content: "\e1d3";
}
.ph-currency-eur:before {
  content: "\e554";
}
.ph-desktop-tower:before {
  content: "\e562";
}
.ph-download-simple-bold:before {
  content: "\e20c";
}
.ph-drop-slash:before {
  content: "\e954";
}
.ph-eye-bold:before {
  content: "\e220";
}
.ph-file:before {
  content: "\e230";
}
.ph-file-pdf:before {
  content: "\e702";
}
.ph-files:before {
  content: "\e710";
}
.ph-game-controller:before {
  content: "\e26e";
}
.ph-gauge:before {
  content: "\e628";
}
.ph-gear:before {
  content: "\e270";
}
.ph-gift:before {
  content: "\e276";
}
.ph-hair-dryer:before {
  content: "\ea66";
}
.ph-house:before {
  content: "\e2c2";
}
.ph-identification-badge:before {
  content: "\e6f6";
}
.ph-info:before {
  content: "\e2ce";
}
.ph-laptop:before {
  content: "\e586";
}
.ph-lightning:before {
  content: "\e2de";
}
.ph-list-bold:before {
  content: "\e2f0";
}
.ph-lock-bold:before {
  content: "\e2fa";
}
.ph-magnifying-glass:before {
  content: "\e30c";
}
.ph-map-pin:before {
  content: "\e316";
}
.ph-megaphone:before {
  content: "\e324";
}
.ph-minus-circle:before {
  content: "\e32c";
}
.ph-newspaper:before {
  content: "\e344";
}
.ph-note-pencil-bold:before {
  content: "\e34c";
}
.ph-paper-plane-tilt:before {
  content: "\e398";
}
.ph-phone:before {
  content: "\e3b8";
}
.ph-plug-charging:before {
  content: "\eb5c";
}
.ph-plus-circle:before {
  content: "\e3d6";
}
.ph-printer:before {
  content: "\e3dc";
}
.ph-question:before {
  content: "\e3e8";
}
.ph-receipt:before {
  content: "\e3ec";
}
.ph-rocket-launch:before {
  content: "\e3fe";
}
.ph-scales:before {
  content: "\e750";
}
.ph-sort-ascending-bold:before {
  content: "\e444";
}
.ph-sort-descending-bold:before {
  content: "\e446";
}
.ph-student:before {
  content: "\e73e";
}
.ph-sun:before {
  content: "\e472";
}
.ph-table:before {
  content: "\e476";
}
.ph-thermometer-simple:before {
  content: "\e5cc";
}
.ph-towel:before {
  content: "\ede6";
}
.ph-trash-bold:before {
  content: "\e4a6";
}
.ph-user-circle-bold:before {
  content: "\e4c4";
}
.ph-warning:before {
  content: "\e4e0";
}
.ph-whatsapp-logo:before {
  content: "\e5d0";
}
.ph-wifi-high:before {
  content: "\e4ea";
}
.ph-wrench:before {
  content: "\e5d4";
}
.ph-x-bold:before {
  content: "\e4f6";
}
