
$switch-width: 34px
$switch-height: calc($switch-width / 2)
$switch-inner-size: calc($switch-width / 2)

.form-switch
  display: flex
  cursor: pointer

  input[type=checkbox]
    display: none

    & + .slider
      margin-left: calc($switch-width / 5)
      margin-right: calc($switch-width / 5)

    &:checked
      & + .slider:before
        transform: translateX($switch-inner-size)

  .slider
    position: relative
    display: block
    width: $switch-width
    height: $switch-height
    border: 1px solid $gris
    transition: .4s
    background-color: $blanc

    &.round
      border-radius: 34px

    &.round:before
      border-radius: 50%

    &:before
      position: absolute
      content: ""
      height: $switch-inner-size
      width: $switch-inner-size
      background-color: $bleu
      -webkit-transition: .4s
      transition: .4s

  // boldify selected option
  // NOTE: css for left option works in browsers starting released after December 2023 − https://caniuse.com/css-has
  span:has(+ input:not(:checked))
    font-weight: bold

  input:checked ~ span:last-child
    font-weight: bold

i.form-switch.ph-info
  font-size: 1rem
  margin-left: .5rem
  cursor: default
