details[open].vc-accordion
  summary
    i.ph-caret-down-bold
      transform: rotate(180deg)

.vc-accordion
  summary
    cursor: pointer
    color: $gris-nuit
    background-color: $blanc
    border-style: var(--border-solid)
    border-color: $gris-neutre
    border-radius: var(--rounded)
    border-width: var(--border-2)
    padding: var(--padding-2)
    font-size: var(--font-size-base)
    line-height: var(--line-height-base)
    margin-bottom: var(--margin-4)
    display: flex
    flex-direction: column
    align-items: center
    text-align: center

.vc-accordion--unfold
  summary
    list-style: none
    justify-content: space-between
    flex-direction: row
    &::marker
      display: none

.js-accordion-trigger
  display: flex
  align-items: center
  cursor: pointer

  > i
    margin-right: spacer(1)

.js-accordion-container.closed
  & > .table-line .icon-when-open
    display: none
  .js-accordion-content
    display: none

.js-accordion-container:not(.closed)
  & > .table-line .icon-when-closed
    display: none

@media #{$breakpoint-larger-than-sm-768px}
  .xs-only-accordion-container.js-accordion-container
    .js-accordion-content
      display: flex
    .js-accordion-trigger
      display: none
