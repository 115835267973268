.illustrated-block-component
  display: flex
  flex-direction: row
  gap: spacer(2)
  margin-bottom: spacer(2)
  &:last-child
    margin-bottom: 0

  .illustration
    align-self: center
    flex-grow: 0
    flex-shrink: 0
    height: 72px
    width: 72px
    img
      object-fit: contain
    &--circle
      background-color: $bleu-light
      border-radius: 50%
      display: flex
      overflow: hidden
      img
        width: 52px
        height: 52px
        margin: auto

  .block
    box-sizing: border-box

  @media #{$breakpoint-smaller-than-sm-768px}
    flex-direction: column
    .illustration
      align-self: start
